
import React, { useEffect, useState, useRef, useMemo } from 'react'
import $ from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Form from 'react-bootstrap/Form';
import { Card } from 'react-bootstrap';

// React icons
import { AiOutlineDownCircle } from "react-icons/ai";
import { TbArrowsRight } from "react-icons/tb";
import { TbArrowsLeft } from "react-icons/tb";
import { TbArrowsDown } from "react-icons/tb";
import { TbArrowsUp } from "react-icons/tb";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { FiDownload } from 'react-icons/fi';
import { HiDocumentDuplicate } from "react-icons/hi";
import { Modal, Table } from "react-bootstrap";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";


//File Imports
import ComboBox from "Features/ComboBox";
//Export Related imports
import PdfExport from 'Features/Exports/PdfExport';
import ExcelExport from "Features/Exports/ExcelExport";
import JsonExport from "Features/Exports/JsonExport";
import CSVExport from "Features/Exports/CSVExport";
import Datatable from 'components/DataTable';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { MdCancelScheduleSend } from "react-icons/md";
import { IoDocumentLock } from "react-icons/io5";


// import valid formate
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ConfigConstants from "assets/Constants/config-constant";
import { RiSearchLine } from 'react-icons/ri';
import { CircularProgress } from '@material-ui/core';


function POListing({ compType = 'Transactions' }) {
  const { requestfor } = useParams();

  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_NAME, USER_ACCESS, DEPARTMENT_ID, DEPARTMENT_NAME, UserId, UserName } = configConstants;
  let [totalsPresent, setTotalsPresent] = useState(false);
  // COMPANY_NAME
  let storeSelectedValues = [];
  const [recordData, setRecordData] = useState([]);
  const [filterComboBox, setFilterComboBox] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [groupByColumns, setGroupByColumns] = useState([]);
  const [selectedValue, setSelectedValue] = useState("")
  const [searchState, setGlobalFilterSearch] = useState('')
  const [searchInputValue, setSearchInputValue] = useState('')


  // PreClosed Details table Data hooks
  const [preClosedPODetailsData, setPreClosedPODetailsData] = useState([])
  const [showPreClosedModal, setPreClosedModal] = useState(false);
  const handleClosePreClosedPopup = () => setPreClosedModal(false)

  //Error Msg
  const handleCloseErrModal = () => {
    setShowErrorMsgModal(false)

  };
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [modalOrderDetails, setModalOrderDetails] = useState('')
  // Confirmation Modal fields
  const closeConfirmationModal = () => {
    setShowSuccessMsgModal(false);
    reload();
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState("");

  let reportName = "Purchase Order Report";

  //useRef
  const pdfExp = useRef();
  const exlsExp = useRef();
  const jsonExp = useRef();
  const csvExp = useRef();
  const pdfExpFilters = {};
  const comboBox = useRef();
  const navigate = useNavigate();
  const validateNumberDateInput = useRef();

  // Table Data
  const [data, setIndentData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [reportType, setReportType] = useState('summary');
  const [isLoading, setIsLoading] = useState(false);
  const [accessControl, setAccessControl] = useState('')

  // Export Fields
  var dataExport = [];
  var columnExport = [];

  useEffect(() => {
    const rptApiCall = async () => {
      setIsLoading(true)
      const availCols = await FnShowAllReportRecords(reportType);
      await fetchFilteredData(PageCurrent, entriesPerPage, availCols)
      setIsLoading(false)
    }
    rptApiCall()
  }, [reportType, compType, requestfor])

  useEffect(async () => {
    $('.hide-show-filters').hide();
  }, [])

  function toggleFilter() {
    $(this).text(function (_, currentText) {
      return currentText == "▼" ? "▲" : "▼";
    }, []);
    $('.hide-show-filters').toggle('fast');
  };

  // Pagination Variables 
  const pageEntriesOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "All", value: 0 },
  ]
  const [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[2].value);
  const [pageCount, setpageCount] = useState(0);
  const [PageCurrent, setcurrentPage] = useState(0);
  const [cmb_purchase_order_status, setPurchaseOrderStatus] = useState('');


  var operatorLabels = { "=": "is", "!=": "is not", "1": "active", "0": "closed", "o": "open", "!*": "none", "*": "any", "~": "contains", "!~": "doesn't contain", "^": "starts with", "$": "ends with", "<=": "less than or equal", ">=": "greater than or equal", '<>=': 'BETWEEN' };
  var operatorByType = {
    "list": ["=", "!="], "status": ["1", "0"], "list_status": ["o", "=", "!", "c", "*"], "list_optional": ["=", "!", "!*", "*"],
    "list_subprojects": ["*", "!*", "=", "!"], "string": ["~", "=", "!~", "!=", "^", "$"], "text": ["~", "!~", "^", "$", "!*", "*"],
    "integer": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"], "float": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"],
    "relation": ["=", "!", "=p", "=!p", "!p", "*o", "!o", "!*", "*"], "tree": ["=", "~", "!*", "*"], "date": ["=", "<=", '>=', '<>='],
  };

  //key for get Request type header
  const requestTypeHeading = () => {
    switch (requestfor) {
      case 'RC':
        return '(Raw Cotton)';
      case 'PRM':
        return '(Cotton Yarn)';
      case 'BL':
        return '(Cotton Bales)';
      case 'GF':
        return '(Greige Fabric)';
      default:
        return 'Order';
    }
  }
  const FnShowAllReportRecords = async (reportIdentifierKey) => {
    try {
      const summaryRptApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/report/FnShowAllReportRecords?reportType=${reportIdentifierKey}&viewName=${reportIdentifierKey === 'summary' ? 'ptv_purchase_order_master_summary_rpt' : 'ptv_purchase_order_details_rpt'}`)
      const summaryResponce = await summaryRptApiCall.json();
      if (summaryResponce.content.length !== 0) {
        var rptColumnHeads = summaryResponce.headerKeys;
        setRecordData(summaryResponce.content)
        let filterBox = [];
        let tempOptionBox = []
        for (let colKey = 0; colKey < rptColumnHeads.length; colKey++) {
          const content = summaryResponce.content;
          const columnName = rptColumnHeads[colKey];
          const value = content[columnName];
          if (value !== null) {
            const myArray = value.split(":");
            switch (myArray[2]) {
              case 'Y':
                filterBox.push({ Headers: myArray[1], accessor: columnName });
                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                break;
              case 'O':
                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                break;
              default:
                break

            }

          }

        }
        setFilterComboBox(filterBox)
        setAvailableColumns(tempOptionBox)
        setGroupByColumns(tempOptionBox)
        return tempOptionBox;
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }
  const FnCheckUserAccess = () => {
    const currentURL = window.location.pathname;
    const obj = USER_ACCESS ? USER_ACCESS.find(item => { return `${item.listing_navigation_url}/${item.url_parameter}` === currentURL; }) : null;
    console.log("Particular Page user access: ", obj);
    return obj;
  }

  // const FnCheckUserAccess = () => {
  //   const currentURL = window.location.pathname;
  //   // const obj = USER_ACCESS ? USER_ACCESS.find(item => `${item.listing_navigation_url}`{compType === 'Transactions'?`/${item.url_parameter}`:null} === currentURL) : null;
  //   const obj = USER_ACCESS ? USER_ACCESS.find(item => {
  //     if (item.listing_navigation_url && compType === 'Transactions') {
  //         return `${item.listing_navigation_url}/${item.url_parameter}` === currentURL;
  //     }else{
  //       return `${item.listing_navigation_url}` === currentURL;
  //     }
  //     return false;
  // }) : null;

  //   console.log("Particular Page user access: ", obj);
  //   return obj;
  // }

  const globalSearchOnChange = async () => {
    if (entriesPerPage !== 0) {
      setcurrentPage(0)
      setEntriesPerPage(0)
      await fetchFilteredData(0, 0, selectedColumns.length !== 0 ? selectedColumns : availableColumns)
    }
    setSearchInputValue(searchState)
  }

  const FnRenderAdditionalInputsOnDateChange = (filter) => {
    if (filter.type === 'D' && document.getElementById(`operators_${filter.id}_id`).value === '<>=') {
      const filterTblRow = document.getElementById(`${filter.id}`);
      const filterTblRowTd = document.createElement('td');

      // Create the first input element
      const dateInput = document.createElement('input');
      dateInput.type = 'date';
      dateInput.id = `values_${filter.id}_id_2`;
      dateInput.className = 'erp_form_control erp_input_field inputValue erp_operator_val ms-4';

      filterTblRowTd.appendChild(dateInput);
      filterTblRow.appendChild(filterTblRowTd);
    } else {
      // Remove the existing td if it exists
      const existingTd = document.getElementById(`values_${filter.id}_id_2`);
      if (existingTd) {
        existingTd.parentNode.removeChild(existingTd);
      }
    }
    return null;
  };

  const fetchFilteredData = async (page, size, availCols) => {

    try {
      setIsLoading(true)
      setTotalsPresent(false)
      const executeQuery = await submitQuery(availCols);
      const formData = new FormData();
      formData.append(`jsonQuery`, executeQuery)
      const requestOptions = { method: 'POST', body: formData };
      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnMShowFilterRecords/${page}/${size}`, requestOptions)
      const responce = await fetchResponse.json();
      const colsSummaryTotal = ['basic_total', 'transport_amount', 'freight_amount', 'packing_amount', 'po_discount_amount', 'other_amount', 'taxable_total',
        'cgst_total', 'sgst_total', 'igst_total', 'roundoff', 'grand_total',];

      const colsDetailsTotal = ['product_material_po_quantity', 'product_material_po_weight', 'product_material_po_approved_quantity', 'product_material_po_approved_weight', 'product_material_po_rejected_quantity', 'product_material_po_rejected_weight', 'pree_closed_quantity', 'pree_closed_weight',
        'purchase_return_quantity', 'purchase_return_weight', 'material_basic_amount', 'material_discount_amount', 'material_taxable_amount', 'material_cgst_total', 'material_sgst_total', 'material_igst_total', 'material_total_amount', 'material_freight_amount',
        // Newly added columns for summation.
        "pending_quantity", "pending_weight", "excess_quantity", "excess_weight", "production_issue_quantity", "production_issue_weight", "production_issue_return_quantity", "production_issue_return_weight", "production_issue_rejection_quantity", "production_issue_rejection_weight", "assembly_production_issue_quantity", "assembly_production_issue_weight", "sales_quantity", "sales_weight", "sales_return_quantity", "sales_return_weight", "sales_rejection_quantity", "sales_rejection_weight", "transfer_issue_quantity", "transfer_issue_weight", "transfer_receipt_quantity", "transfer_receipt_weight", "outsources_out_quantity", "outsources_out_weight", "outsources_in_quantity", "outsources_in_weight", "outsources_rejection_quantity", "outsources_rejection_weight", "loan_receipt_quantity", "loan_receipt_weight", "loan_issue_quantity", "loan_issue_weight", "cancel_quantity", "cancel_weight", "difference_quantity", "difference_weight", "prev_grn_quantity", "prev_grn_weight",
      ];
      const colsCalAverage = ['material_rate', 'material_discount_percent', 'material_cgst_percent', 'material_sgst_percent', 'material_igst_percent', 'hsn_sac_rate', 'agent_percent', 'po_discount_percent',];
      let colsCalculateTotal = reportType === 'summary' ? colsSummaryTotal : colsDetailsTotal;
      let nColumn = [];
      let totals = {}; // Object to store totals of specific columns
      if (responce.content.length > 0) {
        const access_control = FnCheckUserAccess() || { all_access: false, read_access: false, modify_access: false, delete_access: false }; // Setting default access control when USER_ACCESS is undefined
        ;
        setAccessControl(access_control);
        // This for pagination 
        const total = responce.totalElements;
        setpageCount(parseInt(size) !== 0 ? Math.ceil(total / size) : 1);

        // Get column Name & Accessor
        let colNameAccessor;
        let colNameHeader;

        for (let colKey = 0; colKey < availCols.length; colKey++) {
          colNameAccessor = availCols[colKey].accessor
          colNameHeader = availCols[colKey].Headers
          if (colKey === 0) {
            nColumn.push({
              Headers: "Action", accessor: "Action",
              Cell: row => (
                <div style={{ display: "flex" }}>
                  <Tooltip title="view" placement="top">
                    <MDTypography className="erp-view-btn" >
                      <AiFillEye className={`erp-view-btn ${access_control.all_access || access_control.read_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDeleteApprove(row.original, 'view', access_control)} />
                    </MDTypography>
                  </Tooltip>

                  {compType === 'Register' ?
                    null : <>
                      <Tooltip title={'Approve'} placement="top">
                        <MDTypography className={`erp-view-btn ${row.original.purchase_order_status == 'P' && row.original.approved_by_id == UserId ? 'display' : 'd-none'}`}>
                          < BsFillCheckCircleFill className={`erp-view-btn ${access_control.all_access || access_control.approve_access ? 'display' : 'd-none'}`} onClick={(e) => { { viewUpdateDeleteApprove(row.original, 'approve', access_control) } }} />
                        </MDTypography>
                      </Tooltip>
                      <Tooltip title={'Cancel'} placement="top">
                        <MDTypography className={`erp-view-btn ${row.original.purchase_order_status == 'A' && row.original.grn_status == 'P' ? 'display' : 'd-none'}`} >
                          <MdCancelScheduleSend className="erp-view-btn" onClick={() => { viewUpdateDeleteApprove(row.original, 'cancel', access_control) }} />
                        </MDTypography>
                      </Tooltip>
                      <Tooltip title='Copy' placement="top">
                        <MDTypography className="erp-view-btn" >
                          <HiDocumentDuplicate className="erp-view-btn" onClick={e => viewUpdateDeleteApprove(row.original, 'copy', access_control)} />
                        </MDTypography>
                      </Tooltip>
                      <Tooltip title={'Edit'} placement="top">
                        <MDTypography className={`erp-view-btn ${row.original.purchase_order_status !== 'P' ? 'd-none' : 'display'}`}>
                          <MdModeEdit className={`erp-edit-btn ${access_control.all_access || access_control.modify_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDeleteApprove(row.original, 'update', access_control)} />
                        </MDTypography>
                      </Tooltip>
                      <Tooltip title='Delete' placement="top">
                        <MDTypography className={`erp-view-btn ${row.original.purchase_order_status !== 'P' ? 'd-none' : 'display'}`}>
                          <MdDelete className={`erp-delete-btn ${access_control.all_access || access_control.delete_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDeleteApprove(row.original, 'delete', access_control)} />
                        </MDTypography>
                      </Tooltip>
                      <Tooltip title='Pre-Closed' placement="top">
                        <MDTypography className={`erp-view-btn ${(row.original.grn_status === 'I' || row.original.grn_item_status === 'I') && (row.original.purchase_order_status === 'A' || row.original.purchase_order_item_status === 'A') ? 'display' : 'd-none'}`}>
                          <IoDocumentLock className={`erp-view-btn ${access_control.all_access || access_control.special_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDeleteApprove(row.original, 'preClosed', access_control)} />
                        </MDTypography>
                      </Tooltip>
                    </>
                  }
                </div>
              ),
            });
          }
          // nColumn.push({ Headers: colNameHeader, accessor: colNameAccessor });
          nColumn.push({
            Headers: colNameHeader,
            accessor: colNameAccessor,
            width: colNameAccessor === 'purchase_order_no' ? 170 : undefined // Conditionally set width
          });

          // Calculate sum for specific columns for registers
          if (compType === 'Register') {
            if (colsCalculateTotal.includes(colNameAccessor)) {
              const totalValue = responce.content.reduce((acc, curr) => acc + curr[colNameAccessor], 0);
              totals[colNameAccessor] = validateNumberDateInput.current.decimalNumber(totalValue, 4);
            }
            // // Commented this code because prashant sir told to show only the sum of the amounts except rate.
            //  else if (colsCalAverage.includes(colNameAccessor)) {
            //   // Calculate average only for rows where the value is present
            //   const valuesWithPresentData = responce.content.filter(row => row[colNameAccessor] !== null && row[colNameAccessor] !== undefined && row[colNameAccessor] !== 0);
            //   const totalValue = valuesWithPresentData.reduce((acc, curr) => acc + curr[colNameAccessor], 0);
            //   const averageValue = valuesWithPresentData.length > 0 ? totalValue / valuesWithPresentData.length : 0; // Divide by the number of elements with present data
            //   totals[colNameAccessor] = validateNumberDateInput.current.decimalNumber(averageValue.toString(), 4);
            // }
          }
        }
        if (compType === 'Register' && Object.keys(totals).length > 0) {
          responce.content.push(totals);
          setTotalsPresent(true)
        }
        setColumns(nColumn);
        setIndentData(responce.content);

      } else {
        setColumns([]);
        setIndentData([])
      }
      return responce;
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }

  };


  const fetchFilteredDataToExport = async (availCols) => {
    try {
      setIsLoading(true)
      const executeQuery = await submitQuery(availCols);
      const formData = new FormData();
      formData.append(`jsonQuery`, executeQuery)
      const requestOptions = { method: 'POST', body: formData };
      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnShowFilterRecords`, requestOptions)
      const responce = await fetchResponse.json();
      let filterColumnsToExport = [];

      if (responce.content.length > 0) {
        // Get column Name & Accessor
        let colNameAccessor;
        let colNameHeader;

        for (let colKey = 0; colKey < availCols.length; colKey++) {
          colNameAccessor = availCols[colKey].accessor
          colNameHeader = availCols[colKey].Headers

          filterColumnsToExport.push({ Headers: colNameHeader, accessor: colNameAccessor });
        }

        dataExport = responce.content
        columnExport = filterColumnsToExport
      } else {
        dataExport = []
        columnExport = []
      }
      return responce;
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }

  };

  const submitQuery = async (availCols) => {

    try {
      const requiredColumns = ['purchase_order_master_transaction_id', 'purchase_order_no', 'purchase_order_version', 'financial_year', 'product_type_short_name']
      let selectBox = document.getElementById("selectedColumns");
      let group_by = document.getElementById("group_by").value;
      let jsonQuery = { 'viewname': {}, 'selectQuery': {}, 'whereQuery': {}, 'operators': {}, 'group_by': {}, "additionalParam": {}, "orderBy": {}, "logicalOperator": {} };
      storeSelectedValues = []
      if (selectBox.length !== 0) {
        let selectOrder = []; // Array to store the order of elements
        let storeSelectedValues = []; // Assuming this is defined somewhere in your code

        // Loop through selectBox to populate selectQuery and selectOrder
        for (let index = 0; index < selectBox.length; index++) {
          let optionValue = selectBox.options[index].value;
          jsonQuery['selectQuery'][index] = optionValue;
          selectOrder.push(index); // Store the index in the order array
          storeSelectedValues.push(optionValue);
        }

        // Loop through requiredColumns to add missing elements in the specified order
        for (let index = 0; index < requiredColumns.length; index++) {
          const element = requiredColumns[index];
          if (!jsonQuery.selectQuery.hasOwnProperty(element)) {
            // Add the element at the end of the order array and assign the value
            selectOrder.push(selectOrder.length);
            jsonQuery['selectQuery'][selectOrder.length - 1] = element;
          }
        }

        // Now, construct the final selectQuery object using the specified order
        let finalSelectQuery = {};
        for (let orderIndex of selectOrder) {
          finalSelectQuery[orderIndex] = jsonQuery['selectQuery'][orderIndex];
        }

        // Replace the original selectQuery with the finalSelectQuery
        jsonQuery['selectQuery'] = finalSelectQuery;


      } else {
        for (let index = 0; index < availCols.length; index++) {
          let optionValue = availCols[index].accessor;
          jsonQuery['selectQuery'][optionValue] = optionValue;
        }
      }

      for (let selIndex = 0; selIndex < selectedFilters.length; selIndex++) {
        let fieldvalue = selectedFilters[selIndex].id.trim()
        let operatorSelectValue = document.getElementById(`operators_${fieldvalue}_id`).value;
        let valueSelectValue = document.getElementById(`values_${fieldvalue}_id`).value;
        if (selectedFilters[selIndex].type === 'T') {
          switch (operatorSelectValue) {
            case '~':
              operatorSelectValue = "LIKE"
              valueSelectValue = "%" + valueSelectValue + "%";
              break;
            case '!~':
              operatorSelectValue = "NOT LIKE"
              valueSelectValue = "%" + valueSelectValue + "%";
              break;
            case '^':
              operatorSelectValue = "LIKE"
              valueSelectValue = "%" + valueSelectValue;
              break;
            case '$':
              operatorSelectValue = "LIKE"
              valueSelectValue = valueSelectValue + "%";
              break;
            default:
              break;
          }
        }

        if (selectedFilters[selIndex].type === 'D' && operatorSelectValue === '<>=') {
          if (document.getElementById(`values_${fieldvalue}_id_2`).value !== '' && document.getElementById(`values_${fieldvalue}_id`).value !== '') {
            valueSelectValue = `${document.getElementById(`values_${fieldvalue}_id_2`).value}`
            operatorSelectValue = `BETWEEN '${document.getElementById(`values_${fieldvalue}_id`).value}' AND `
            // this for display filters on reports
            pdfExpFilters[selectedFilters[selIndex].label] = valueSelectValue + ` BETWEEN ` + `${document.getElementById(`values_${fieldvalue}_id`).value}`;
          } else {
            continue;
          }

        } else {
          // this for display filters on reports
          pdfExpFilters[selectedFilters[selIndex].label] = valueSelectValue;
        }
        jsonQuery['whereQuery'][fieldvalue] = valueSelectValue;
        jsonQuery['operators'][fieldvalue] = operatorSelectValue;
      }

      if (requestfor !== "NA") {
        jsonQuery['additionalParam']['product_type_short_name'] = requestfor;
      } else {
        jsonQuery['whereQuery']['product_type_short_name'] = 'RC';
        jsonQuery['operators']['product_type_short_name'] = '!=';
      }
      var purchaseOrderStatusVal = document.getElementById("cmb_purchase_order_status").value;

      if (purchaseOrderStatusVal !== "" && reportType !== 'summary') {
        jsonQuery['additionalParam']['purchase_order_item_status'] = purchaseOrderStatusVal;
      } else if (purchaseOrderStatusVal !== "" && reportType === 'summary') {
        jsonQuery['additionalParam']['purchase_order_status'] = purchaseOrderStatusVal;
      }

      jsonQuery['group_by']["GROUP BY"] = group_by;
      jsonQuery['additionalParam']['is_delete'] = "0";
      jsonQuery['additionalParam']['company_id'] = COMPANY_ID;
      if (parseInt(DEPARTMENT_ID) !== 9) {
        jsonQuery['additionalParam']['approved_by_id'] = UserId;
        jsonQuery['logicalOperator']['approved_by_id'] = "  OR ";
        jsonQuery['additionalParam']['created_by'] = UserName;
        jsonQuery['logicalOperator']['created_by'] = "  OR ";
      }

      // jsonQuery['orderBy']['ORDER BY'] = 'purchase_order_master_transaction_id';
      jsonQuery['orderBy']['ORDER BY'] = `
      CASE 
        WHEN purchase_order_status = 'P' THEN 1 
        WHEN purchase_order_status = 'A' THEN 2
        WHEN purchase_order_status = 'X' THEN 3 
        WHEN purchase_order_status = 'Z' THEN 4 
        WHEN purchase_order_status = 'B' THEN 5
        WHEN purchase_order_status = 'I' THEN 6
        WHEN purchase_order_status = 'C' THEN 7
        WHEN purchase_order_status = 'R' THEN 8
          ELSE 9
      END ASC, purchase_order_master_transaction_id`;



      if (reportType === 'summary') {
        reportName = "Purchase Order Summary Report"
        jsonQuery['viewname']['viewName'] = 'ptv_purchase_order_master_summary'
      } else {
        reportName = "Purchase Order Details Report"
        jsonQuery['viewname']['viewName'] = 'ptv_purchase_order_details'
      }

      let executeQuery = JSON.stringify(jsonQuery)
      return executeQuery;

    } catch (error) {
      console.log("error", error);
      navigate('/Error')
    }
  }

  async function viewUpdateDeleteApprove(data, key, access_control) {

    let modules_forms_id = access_control.modules_forms_id;
    const product_type_short_name = data.product_type_short_name;
    const updateData = {
      purchase_order_master_transaction_id: data.purchase_order_master_transaction_id,
      purchase_order_no: data.purchase_order_no,
      purchase_order_version: data.purchase_order_version,
      financial_year: data.financial_year,
    }
    if (key === 'view' || key === 'delete' || key === 'copy') {
      key === 'delete' ? infoForUpdate(updateData, 'delete', modules_forms_id, product_type_short_name) : key === 'copy' ? infoForUpdate(updateData, 'copy', modules_forms_id, product_type_short_name) : infoForUpdate(updateData, 'view', modules_forms_id, product_type_short_name);
    }

    switch (key) {
      case 'update':
        if (data.purchase_order_status === 'P' || (data.purchase_order_status === 'A' && data.purchase_order_life === 'O')) {
          infoForUpdate(updateData, 'update', modules_forms_id, product_type_short_name);
        }
        break;
      case 'approve':
        if (data.purchase_order_status === 'P' || (data.purchase_order_status === 'A' && data.purchase_order_life === 'O')) {
          infoForUpdate(updateData, 'approve', modules_forms_id, product_type_short_name);
        }
        break;
      case 'cancel':
        if (data.purchase_order_status === 'A' || data.grn_status === 'P') {
          infoForUpdate(updateData, 'cancel', modules_forms_id, product_type_short_name);
        }
        break;
      case 'preClosed':
        if (data.grn_status === 'I' || data.grn_item_status === 'I') {
          try {
            setIsLoading(true)
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnGetPartialgrnMaterials/${updateData.purchase_order_version}/${updateData.financial_year}/${COMPANY_ID}?purchase_order_no=${updateData.purchase_order_no}`)
            const responce = await apiCall.json();
            console.log(responce);
            setPreClosedModal(true)
            if (responce.PurchaseOrderDetailsRecords !== null && responce.PurchaseOrderDetailsRecords.length > 0) {
              const { purchase_order_no, purchase_order_date, purchase_order_version, supplier_name, } = responce.PurchaseOrderMasterRecord;
              setPreClosedPODetailsData(
                responce.PurchaseOrderDetailsRecords.map((item) => ({
                  ...item,
                  product_grn_quantity: item.product_material_po_approved_quantity - item.pending_quantity,
                  purchase_order_item_status: 'Z',
                  pre_closed_remark: ""
                }))
              );
              $('#txt_purchase_order_no_id').append(purchase_order_no);
              $('#txt_purchase_order_no_id').attr('purchase_order_master_transaction_id', updateData.purchase_order_master_transaction_id);
              $('#txt_purchase_order_date_id').append(validateNumberDateInput.current.formatDateToDDMMYYYY(purchase_order_date));
              $('#txt_purchase_order_version_id').append(purchase_order_version)
              $('#txt_supplier_name_id').append(supplier_name)
            } else {
              setPreClosedPODetailsData([]);
            }
          } catch (error) {
            console.log(error);
            setIsLoading(false)
          } finally {
            setIsLoading(false)
          }

        }
        break;
      default:
        break;
    }
  }

  const infoForUpdate = (updateData, key, modules_forms_id, product_type_short_name) => {
    sessionStorage.removeItem('filteredMaterialData');
    localStorage.removeItem('purchaseOrdCommonTermsData');
    localStorage.removeItem('purchaseOrdPaymentTermsData');
    localStorage.removeItem('purchaseOrdScheduleData');
    localStorage.removeItem('taxationSummary');
    navigate("/Transactions/TPurchaseOrder/ManualPO/FrmPurchaseOrderEntry", { state: { idList: updateData, keyForViewUpdate: key, compType: compType, requestfor: requestfor, modules_forms_id: modules_forms_id } })
  }

  const handlePageClick = async (pageNo) => {
    let currentPage = pageNo.selected;
    setcurrentPage(currentPage);
    const commentsFormServer = await fetchFilteredData(currentPage, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    console.log("commentsFormServer: ", commentsFormServer)

  }

  const handlePageCountClick = async () => {
    let count = document.getElementById("page_entries_id").value;
    setEntriesPerPage(count)
    setcurrentPage(0)
    await fetchFilteredData(0, count, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
  }

  const moveOptionTop = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);
    if (selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
      setSelectedColumns([movedColumn, ...newSelectedColumns]);
    }
  };

  const moveOptionBottom = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);
    if (selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
      setSelectedColumns([...newSelectedColumns, movedColumn]);
    }
  };


  const moveOptionUp = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex > 0) {
      const newSelectedColumns = [...selectedColumns];
      [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex - 1]] = [newSelectedColumns[selectedIndex - 1], newSelectedColumns[selectedIndex]];
      setSelectedColumns(newSelectedColumns);
    }
  };

  const moveOptionDown = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex < selectedColumns.length - 1 && selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex + 1]] = [newSelectedColumns[selectedIndex + 1], newSelectedColumns[selectedIndex]];
      setSelectedColumns(newSelectedColumns);
    }
  };

  const moveAllToSelectedColumns = () => {
    setSelectedColumns([...selectedColumns, ...availableColumns]);
    setAvailableColumns([]);
  }

  const addAvailableColumns = () => {
    const selectedOption = document.getElementById("selectedColumns");
    const selectedValue = selectedOption.value;
    if (selectedValue) {
      const selectedColumn = selectedColumns.find(column => column.accessor === selectedValue);
      if (selectedColumn) {
        setAvailableColumns([...availableColumns, selectedColumn]);
        setSelectedColumns(selectedColumns.filter(column => column.accessor !== selectedValue));
      }
    }
  }
  const moveAllToAvailableColumns = () => {
    // Move all selected columns to available columns
    setAvailableColumns([...availableColumns, ...selectedColumns]);

    // Clear the selected columns
    setSelectedColumns([]);
  };

  const addSelectedColumns = () => {
    const selectedOption = document.getElementById("availableColumns");
    const selectedValue = selectedOption.value;
    if (selectedValue) {
      const selectedColumn = availableColumns.find(column => column.accessor === selectedValue);
      if (selectedColumn) {
        setAvailableColumns(availableColumns.filter(column => column.accessor !== selectedValue));
        setSelectedColumns([...selectedColumns, selectedColumn]);
      }
    }
  }

  const addFilterSelect = async () => {

    let masterList;
    let filterText = document.getElementById('add_filter_select');
    if (filterText.value !== '0') {
      document.querySelectorAll("#add_filter_select option").forEach(opt => {
        if (opt.value === filterText.value) {
          opt.disabled = true;
        }
      });
      const value = recordData[filterText.value];
      const myArray = value.split(":");
      const newFilter = {
        id: filterText.value,
        label: myArray[1],
        type: myArray[3],
        dataKey: myArray[5],
        operatorArray: [],
        dataArray: []
      };
      switch (myArray[3]) {
        case 'C':
          if (myArray[5] === 'O') {
            resetGlobalQuery();
            globalQuery.columns.push(`DISTINCT (${filterText.value})`);
            globalQuery.conditions.push({
              field: "company_id",
              operator: "=",
              value: COMPANY_ID
            });
            globalQuery.conditions.push({
              field: "is_delete",
              operator: "=",
              value: 0
            });
            globalQuery.table = myArray[4]
            masterList = await comboBox.current.fillFiltersCombo(globalQuery)
          } else {
            // masterList = await comboBox.current.fillMasterData(myArray[4], "", "")
            if (filterText.value === "approved_by" || filterText.value === "approved_by_name") {
              resetGlobalQuery();
              globalQuery.columns.push("user_id as field_id");
              globalQuery.columns.push("user_name as field_name");
              globalQuery.table = "amv_modules_forms_user_access";
              globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
              globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: accessControl.modules_forms_id });
              globalQuery.conditions.push({ field: "approve_access", operator: "=", value: 1 });
              // globalQuery.table = myArray[4];
              masterList = await comboBox.current.fillFiltersCombo(globalQuery)
            }
            else if (filterText.value === "department_name") {
              resetGlobalQuery();
              globalQuery.columns = ['field_id', 'field_name'];
              globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
              globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
              globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
              globalQuery.table = myArray[4];
              masterList = await comboBox.current.fillFiltersCombo(globalQuery)
            } else if (filterText.value === "sub_department_name") {
              resetGlobalQuery();
              globalQuery.columns = ['field_id', 'field_name'];
              globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
              globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
              globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
              globalQuery.table = myArray[4];
              masterList = await comboBox.current.fillFiltersCombo(globalQuery)
            } else {
              masterList = await comboBox.current.fillMasterData(myArray[4], "", "")
            }
          }

          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = masterList
          break;
        case 'P':
          let propertyList = await comboBox.current.fillComboBox(myArray[4])
          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = propertyList
          break;
        case 'T':
          newFilter.operatorArray = operatorByType.string
          break;
        case 'H':
          // Extracting values within parentheses from the last element
          const valuesInParentheses = myArray[myArray.length - 1].slice(1, -1);
          // Splitting the string into an array of values
          const resultArray = valuesInParentheses.split(',');
          console.log(resultArray);
          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = resultArray
          break;
        case 'D':
          newFilter.operatorArray = operatorByType.date
          break;
      }
      // Update the state with the new filter
      setSelectedFilters(prevFilters => [...prevFilters, newFilter]);
    }
  }

  const exporttoPdf = async () => {
    try {

      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);

      if (filtrdata.length !== 0) {
        var filtrObjKeys = Object.keys(pdfExpFilters);
        for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
          var key = filtrObjKeys[objKey];
          var value = pdfExpFilters[key];
        }
      }
      if (dataExport.length !== 0) {
        pdfExp.current.pdf(dataExport, columnExport, reportName, pdfExpFilters)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }

  const exporttoExcel = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);

      // Filter out columns with 'Action' accessor
      const filteredColumns = columns.filter(item => item.accessor !== 'Action');
      // Function to format date columns
      const formatDateColumns = (data) => {
        return data.map(item => {
          const formattedItem = { ...item };
          filteredColumns.forEach(column => {
            if (column.Headers.includes(' Date') && formattedItem[column.accessor]) {
              formattedItem[column.accessor] = new Date(formattedItem[column.accessor]).toLocaleDateString('en-GB'); // Format as desired
            }
          });
          return formattedItem;
        });
      };

      // Format date columns in the data to be exported
      const formattedData = formatDateColumns(filtrdata.content);

      if (dataExport.length !== 0) {
        exlsExp.current.exportToExcel(formattedData, filteredColumns, reportName)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }

  const exporttoJSON = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        let data = dataExport.map(element => {
          return columnExport.reduce((dataJson, col) => {
            dataJson[col.accessor] = element[col.accessor];
            return dataJson;
          }, {});
        });
        jsonExp.current.json(data, reportName)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }


  const exportToCSV = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        let data = dataExport.map(element => {
          return columnExport.reduce((dataJson, col) => {
            dataJson[col.accessor] = element[col.accessor];
            return dataJson;
          }, {});
        });
        csvExp.current.csv(data, columnExport, reportName)
      }

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }

  const removeFilter = (filterId) => {
    // Remove the filter from the state
    setSelectedFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
    document.querySelectorAll("#add_filter_select option").forEach(opt => {
      if (opt.value === filterId) {
        opt.disabled = false;
      }
    });
    $("#add_filter_select").val('0');
  };


  const openForm = () => {
    const access_control = FnCheckUserAccess();
    sessionStorage.removeItem('filteredMaterialData');
    localStorage.removeItem('purchaseOrdCommonTermsData');
    localStorage.removeItem('purchaseOrdPaymentTermsData');
    localStorage.removeItem('purchaseOrdScheduleData');
    localStorage.removeItem('taxationSummary');
    navigate("/Transactions/TPurchaseOrder/ManualPO/FrmPurchaseOrderEntry", { state: { idList: '', keyForViewUpdate: '', compType: compType, requestfor: requestfor, modules_forms_id: access_control.modules_forms_id } })
  }

  const reload = () => {
    window.location.reload();
  }

  const switchReport = () => {
    setSelectedColumns([])
    setSelectedFilters([])
    setFilterComboBox([])
    let activeReport = document.querySelector('input[name=summaryOrDetails]:checked').value
    setReportType(activeReport)
  }


  // fn to remove material
  const removeMaterial = (indexToRemove, setStateCallback, currentRow) => {
    if (indexToRemove === undefined || indexToRemove < 0 || indexToRemove >= preClosedPODetailsData.length) {
      console.error("Invalid index provided.");
      return;
    }
    // Create a new array excluding the element at indexToRemove
    const updatedDetailsData = preClosedPODetailsData.filter((_, index) => index !== indexToRemove);

    // Update state using the provided callback
    setStateCallback(updatedDetailsData);

    // remove the schedules for that material
    let preClosedMaterials = [...preClosedPODetailsData];
    if (preClosedMaterials.length > 0) {
      const updatedIndentMaterials = preClosedMaterials.filter(
        item => item.product_material_id !== currentRow.product_material_id
      );
      setPreClosedPODetailsData(updatedIndentMaterials);
    }
  }

  const updatePreClosedDetailsTblData = (currentRowData, event) => {
    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
    let eventId = document.querySelector('#' + event.target.id);
    let clickedColName = event.target.getAttribute('Headers');

    // Destructuring the object
    let {
      product_material_po_quantity,
      product_material_po_approved_quantity,
      pending_quantity,
      pree_closed_quantity,
    } = currentRowData;

    let enteredValue = event.target.value;
    if (isNaN(enteredValue)) {
      enteredValue = 0;
    } else if (enteredValue != 0 || enteredValue != "") {
      delete eventId.parentElement.dataset.tip;
    }

    switch (clickedColName) {
      case 'pree_closed_quantity':
        if (enteredValue > pending_quantity) {
          currentRowData[clickedColName] = '';
          eventId.parentElement.dataset.tip = 'Pre-Closed quantity cannot be greater than pending quantity...!';
        } else {
          currentRowData[clickedColName] = enteredValue;
          currentRowData['purchase_order_item_status'] = 'Z';
          delete eventId.parentElement.dataset.tip;
        }
        break;
      case 'pre_closed_remark':
        currentRowData[clickedColName] = event.target.value;
        break;
      default:
        break;
    }

    const tblRows = [...preClosedPODetailsData]
    tblRows[arrayIndex] = currentRowData
    setPreClosedPODetailsData(tblRows);
  }


  const renderPreClosedPODetails = useMemo(() => {
    return <>
      {preClosedPODetailsData.length !== 0 ?
        <Table className="erp_table erp_table_scroll" id='pre_closed_detailsTable_id' responsive bordered striped>
          <thead className="erp_table_head">
            <tr>
              <th className={`erp_table_th`}>Action</th>
              <th className="erp_table_th">Sr No</th>
              <th className="erp_table_th">Material Code</th>
              <th className={`erp_table_th`} style={{ width: '100px', paddingRight: '120px' }}>Material Name</th>
              <th className="erp_table_th">PO Quantity</th>
              <th className={`erp_table_th`}>Approved Quantity</th>
              <th className="erp_table_th">Received Quantity</th>
              <th className="erp_table_th">Pending Quantity</th>
              <th className="erp_table_th">Pre-Closed Quantity</th>
              <th className="erp_table_th">Pre-Closed Remark</th>
              <th className={`erp_table_th `} style={{ width: '100px', paddingRight: "30px" }}>Material Status</th>
              {/* <th className="erp_table_th">Std Weight</th> */}
            </tr>
          </thead>

          <tbody>
            {preClosedPODetailsData.map((poItem, index) => (
              <tr rowIndex={index} >
                <td className={`erp_table_td sticky-column`}>
                  <div style={{ display: "flex" }}>
                    <Tooltip title="Delete" placement="top">
                      <MDTypography className="erp-view-btn">
                        <MdDelete className="erp-delete-btn" onClick={() => removeMaterial(index, setPreClosedPODetailsData, poItem)} />
                      </MDTypography>
                    </Tooltip>
                  </div>
                </td>
                <td className="erp_table_td text-end">{index + 1}</td>
                <td className="erp_table_td text-end">{poItem.product_material_code}</td>
                <td className={`erp_table_td`} style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{poItem.product_material_name}</td>
                <td className="erp_table_td text-end">{poItem.product_material_po_quantity}</td>
                <td className="erp_table_td text-end">{poItem.product_material_po_approved_quantity}</td>
                <td className="erp_table_td text-end">{poItem.product_grn_quantity}</td>
                <td className="erp_table_td text-end">{poItem.pending_quantity}</td>
                <td className="erp_table_td ">
                  <input
                    type="text"
                    className="erp_input_field mb-0 text-end" Headers='pree_closed_quantity'
                    id={`pree_closed_quantity_${index}`}
                    value={poItem.pree_closed_quantity} // Use a function to get the input value based on propertyName
                    onChange={(e) => { updatePreClosedDetailsTblData(poItem, e); }}
                    onBlur={(e) => { updatePreClosedDetailsTblData(poItem, e); }}
                  /></td>
                <td className="erp_table_td ">
                 <textarea
                    type="text"
                    className="erp_input_field mb-0" Headers='pre_closed_remark'
                    id={`pre_closed_remark_${index}`}
                    value={poItem.pre_closed_remark} // Use a function to get the input value based on propertyName
                    onChange={(e) => { updatePreClosedDetailsTblData(poItem, e); }}
                  /></td>
                <td className="erp_table_td ">
                  <select id={`purchase_order_item_status_${index}`} value={poItem.purchase_order_item_status} className="form-select form-select-sm"
                    Headers='purchase_order_item_status' disabled >
                    <option value="P">Pending</option>
                    <option value="A">Approved</option>
                    <option value="R">Rejected</option>
                    <option value="I">Partial Receipt</option>
                    <option value="C">Completed</option>
                    <option value="X">Canceled</option>
                    <option value="Z">PreeClosed</option>
                  </select>
                </td>
                {/* <td className="erp_table_td text-end">{poItem.product_rm_std_weight}</td> */}
              </tr>
            ))}
          </tbody>
        </Table> : null
      }
    </>
  }, [preClosedPODetailsData]);

  //validate po form 
  const FnValidatePoOrder = async () => {
    let PoDetailsIsValid = false;
    $('#pre_closed_detailsTable_id tbody tr').each(function () {

      let currentTblRow = $(this);
      let preClosedQty = parseFloat(currentTblRow.find('input[id^="pree_closed_quantity_"]').val());

      if (isNaN(preClosedQty) || preClosedQty <= 0 || preClosedQty === '') {
        $(currentTblRow.find('input[id^="pree_closed_quantity_"]'))[0].parentElement.dataset.tip = 'Pre-Closed quantity should not be a zero or blank...!';
        $(currentTblRow.find('input[id^="pree_closed_quantity_"]'))[0].focus();
        return PoDetailsIsValid = false;
      }
    });
    return PoDetailsIsValid = true;
  }

  // update material details as preclosed
  const FnPreClosedPODetails = async () => {

    let povalidate = true;
    povalidate = await FnValidatePoOrder();
    if (povalidate) {
      setIsLoading(true)
      let json = { 'TransHeaderFooterData': {}, 'TransDetailData': [], 'commonIds': {} }
      let po_master_transaction_id = 0;
      debugger
      // Trans PO DetailsData
      for (let poDetailIndex = 0; poDetailIndex < preClosedPODetailsData.length; poDetailIndex++) {
        const poDetailDataElement = preClosedPODetailsData[poDetailIndex];
        const detailRowProperty = {}
        detailRowProperty['company_id'] = COMPANY_ID
        // detailRowProperty['company_branch_id'] = COMPANY_BRANCH_ID
        // detailRowProperty['financial_year'] = FINANCIAL_SHORT_NAME
        detailRowProperty['purchase_order_no'] = poDetailDataElement.purchase_order_no;
        detailRowProperty['purchase_order_date'] = poDetailDataElement.purchase_order_date;
        detailRowProperty['purchase_order_version'] = poDetailDataElement.purchase_order_version;
        detailRowProperty['product_material_id'] = poDetailDataElement.product_material_id
        detailRowProperty['product_material_po_quantity'] = poDetailDataElement.product_material_po_quantity
        // detailRowProperty['product_material_po_weight'] = poDetailDataElement.product_material_po_weight
        detailRowProperty['product_material_po_approved_quantity'] = poDetailDataElement.product_material_po_approved_quantity
        detailRowProperty['pree_closed_quantity'] = poDetailDataElement.pree_closed_quantity
        // detailRowProperty['pree_closed_weight'] = poDetailDataElement.pree_closed_weight
        detailRowProperty['pre_closed_remark'] = poDetailDataElement.pre_closed_remark
        detailRowProperty['purchase_order_item_status'] = poDetailDataElement.purchase_order_item_status
        detailRowProperty['modified_by'] = UserName
        detailRowProperty['purchase_order_master_transaction_id'] = poDetailDataElement.purchase_order_master_transaction_id
        detailRowProperty['purchase_order_details_transaction_id'] = poDetailDataElement.purchase_order_details_transaction_id;

        json.TransDetailData.push(detailRowProperty)
        po_master_transaction_id = poDetailDataElement.purchase_order_master_transaction_id;
      }
      console.log("json of Preclosed New: ", json);
      const formData = new FormData();
      formData.append(`poPreClosedData`, JSON.stringify(json))
      const requestOptions = {
        method: 'POST',
        body: formData
      };

      const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnUpdatePreClosed/${COMPANY_ID}/${po_master_transaction_id}`, requestOptions);
      const responce = await apicall.json();
      if (responce.success === "0") {
        setErrMsg(responce.error)
        setShowErrorMsgModal(true)
      } else {
        setSuccMsg(responce.message)
        setShowSuccessMsgModal(true)
      }
    }
  }




  return (
    <>
      <ComboBox ref={comboBox} />
      <PdfExport ref={pdfExp} />
      <ExcelExport ref={exlsExp} />
      <JsonExport ref={jsonExp} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <CSVExport ref={csvExp} />

      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress color="primary" />
            <span id="spinner_text" className="text-dark">Loading...</span>
          </div>
        </div> :
        null}

      <DashboardLayout>
        <div className='main_heding'>
          <label className='erp-form-label-lg'>Purchase {requestTypeHeading()}</label>
        </div>
        <button className="erp_toggle-filter" onClick={toggleFilter}>
          <AiOutlineDownCircle className="rotate_filtr_arrow" />
          <MDTypography component="label" variant="button" className="erp-form-label-md-lg">
            &nbsp; filters
          </MDTypography></button>


        <div className="hide-show-filters card filter">
          <div className="row">
            <div className="col-sm-6 erp_filter_table_avl_col">
              <div className='container shadow h-100 rounded erp_group-resl_container'>
                <div className='row erp_filter_row_tab_view'>
                  <div className="erp_form_radio">
                    <div className="fCheck">
                      <Form.Check
                        className="erp_radio_button erp-form-label-md"
                        label="Summary"
                        type="radio"
                        value="summary"
                        name="summaryOrDetails"
                        onChange={() => switchReport()}
                        defaultChecked
                      />
                    </div>
                    <div className="sCheck">
                      <Form.Check
                        className="erp_radio_button erp-form-label-md"
                        label="Details"
                        value="details"
                        type="radio"
                        onChange={() => switchReport()}
                        name="summaryOrDetails"
                      />
                    </div>
                  </div>
                </div>
                <div className='row erp_filter_row_tab_view'>
                  <div className='col-sm-4'>
                    <span>
                      <MDTypography component="label" variant="button" className="erp-form-label-md">
                        Available Columns
                      </MDTypography>
                      <select size="10" id="availableColumns" className="erp_form-select-sm-filter erp_form-select-filter">
                        {availableColumns?.map(column => (
                          <option value={column.accessor}>  {column.Headers} </option>
                        ))}
                      </select>

                    </span>
                  </div>

                  <div className='col-sm-1'>
                    <div className="buttons" id="buttons_row1">
                      <TbArrowsRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToSelectedColumns} /><br></br>
                      <HiOutlineArrowNarrowRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={addSelectedColumns} /><br></br>
                      <HiOutlineArrowNarrowLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={addAvailableColumns} /><br></br>
                      <TbArrowsLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToAvailableColumns} />
                    </div>
                  </div>

                  <div className='col-sm-4'>
                    <div className="col">
                      <MDTypography component="label" variant="button" className="erp-form-label-md">
                        Selected Columns
                      </MDTypography>

                      <select size="10" id="selectedColumns" className="erp_form-select-sm-filter erp_form-select-filter" onChange={e => setSelectedValue(e.target.value)}>
                        {selectedColumns.map((column, index) => (
                          <option key={index} value={column.accessor}>{column.Headers}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-1'>
                    <div className="buttons" id="buttons_row2">
                      <TbArrowsUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionTop} /><br></br>
                      <HiOutlineArrowNarrowUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionUp} /><br></br>
                      <HiOutlineArrowNarrowDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionDown} /><br></br>
                      <TbArrowsDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionBottom} />
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-sm-6 erp_filter_group-by-result">
              <div className='container shadow h-100 rounded erp_group-resl_container'>
                <div className='row erp_group-reslut-by'>
                  <div className='col-sm-3 filtr_gropBy'>
                    <MDTypography
                      className="erp-form-label-md"
                      component="label"
                      variant="button"
                    >Group results by</MDTypography>
                  </div>
                  <div className='col filtr_gropBy'>
                    <Form.Select size="sm" className="erp_form_control operatorSelect" id="group_by">
                      <option value=""></option>
                      {groupByColumns?.map(column => (
                        <option value={column.accessor}> {column.Headers} </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="row">
                  <div className='col-sm-3'>
                    <MDTypography component="label" variant="button" className="erp-form-label-md">
                      Add Filter
                    </MDTypography>
                  </div>
                  <div className="col">
                    <Form.Select size="sm" onChange={addFilterSelect} className="erp_form_control group_by operatorSelect erp_add_filter" id="add_filter_select" >
                      <option value="0"></option>
                      {filterComboBox?.map(column => (
                        <option value={column.accessor}>{column.Headers}</option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <table id="filters-table" className='erp-filters-table-scroll'>
                  <tbody>
                    {selectedFilters.map(filter => (
                      <tr id={filter.id} key={filter.id}>
                        <td>
                          <input
                            type="checkbox"
                            id={`cb_${filter.id}_id`}
                            value={filter.id}
                            checked
                            onClick={() => removeFilter(filter.id)}
                          />
                          <label className='erp-form-label'>&nbsp;{filter.label}</label>
                        </td>
                        {/* Operators */}
                        <td>
                          {(filter.type === 'C' || filter.type === 'P' || filter.type === 'H') && (
                            <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                              {filter.operatorArray.map(operator => (
                                <option key={operator} value={operator}>
                                  {operatorLabels[operator]}
                                </option>
                              ))}
                            </select>
                          )}
                          {filter.type === 'T' && (
                            <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                              {filter.operatorArray.map(operator => (
                                <option key={operator} value={operator}>
                                  {operatorLabels[operator]}
                                </option>
                              ))}
                            </select>
                          )}

                          {filter.type === 'D' && (
                            <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control" onChange={() => FnRenderAdditionalInputsOnDateChange(filter)}>
                              {filter.operatorArray.map(operator => (
                                <option key={operator} value={operator}>
                                  {operatorLabels[operator]}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>
                        {/* Values */}
                        <td>
                          {filter.type === 'C' && (
                            <select
                              id={`values_${filter.id}_id`}
                              className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control"
                            >
                              {filter.dataArray.map((item, index) => (
                                <option
                                  key={index}
                                  value={filter.dataKey === 'O' ? item[filter.id] : item.field_name}
                                >
                                  {filter.dataKey === 'O' ? item[filter.id] : item.field_name}
                                </option>
                              ))}
                            </select>
                          )}

                          {filter.type === 'P' && (
                            <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                              {filter.dataArray.map((item, index) => (
                                <option key={index} value={item.field_name}>
                                  {item.field_name}
                                </option>
                              ))}
                            </select>
                          )}
                          {filter.type === 'T' && (
                            <input type="text" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />

                          )}
                          {filter.type === 'D' && (<>
                            <input type="date" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />
                          </>
                          )}
                          {filter.type === 'H' && (
                            <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                              {filter.dataArray.map((operator, index) => (
                                <option key={index} value={operator}>
                                  {operator}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row btn_row_class">
          <div className="col-sm-8" >
            <MDButton className={`btn erp-gb-button ${compType === 'Register' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={openForm}>Add</MDButton> &nbsp;
            <MDButton class="btn erp-gb-button" variant="button" fontWeight="regular" onClick={() => fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns)}>Apply Filter</MDButton> &nbsp;
            <MDButton class="btn erp-gb-button" variant="button" fontWeight="regular" onClick={reload}>Clear Filter</MDButton>&nbsp;

            <span className="page_entries">
              <MDTypography component="label" className="erp-form-label-md" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Entries per page</MDTypography>

              <Form.Select onChange={handlePageCountClick} value={entriesPerPage} className="erp_page_select erp_form_control" id="page_entries_id" >
                {pageEntriesOptions.map(pageEntriesOptions => (
                  <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                ))}
              </Form.Select>
              <MDTypography component="label" className="erp-form-label-md ps-2" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Purchase Order Status</MDTypography>
              <Form.Select className="erp_page_select erp_form_control" style={{ width: '110px' }} id="cmb_purchase_order_status" value={cmb_purchase_order_status}
                onChange={async (e) => {
                  setPurchaseOrderStatus(e.target.value);
                  fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
                }}>
                <option value='' style={{ color: 'black' }}>Select All</option>
                <option value="P" style={{ color: 'black' }}>Pending</option>
                <option value="A" style={{ color: 'black' }}>Approved</option>
                <option value="R" style={{ color: 'black' }}>Rejected</option>
                <option value="I" style={{ color: 'black' }}>Partial Issue</option>
                <option value="C" style={{ color: 'black' }}>Completed</option>
                <option value="X" style={{ color: 'black' }}>Canceled</option>
                <option value="Z" style={{ color: 'black' }}>PreeClosed</option>
                <option value="B" style={{ color: 'black' }}>Bill Booked</option>
              </Form.Select>
            </span>
          </div>
          <div className="col-4 pagination_id">
            <span className="exports">
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoPdf()}>PDF<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoExcel()}>EXCEL<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exportToCSV()}>CSV<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoJSON()}>JSON<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
            </span>

          </div>

        </div>


        {data.length !== 0 ? <>
          {/* <div className="row mt-1">
            <div className="col-sm-3">
              <div class="input-group">
                <input type="text" className='erp_input_field form-control' style={{ height: '30px' }} value={searchState || ''}
                  onChange={(e) => setGlobalFilterSearch(e.target.value)} placeholder="Search!..." aria-describedby="basic-addon2" />
                <span class="input-group-text" id="basic-addon2" onClick={() => { setSearchInputValue(''); setGlobalFilterSearch('') }}><FaTimes /></span>
              </div>
            </div>
            <div className="col-sm-1">
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => globalSearchOnChange()}> <RiSearchLine className="erp-download-icon-btn" /> </MDButton>
            </div>
          </div> */}
          <Datatable data={data} columns={columns} freezeColumnCount={5} stateValue={searchInputValue} showTotals={totalsPresent} />

          {pageCount !== 1 && compType !== 'Register' ?
            <ReactPaginate
              className='erp_pagination'
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link erp-gb-button"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link erp-gb-button"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link erp-gb-button"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"} /> : null}
        </> : <Card id="NoRcrdId" >
          <Card.Body>No records found...</Card.Body>
        </Card>}


      </DashboardLayout>
      {/* Modal for Indent Details */}
      <Modal size="lg" className='erp_document_Form' show={showPreClosedModal} onHide={handleClosePreClosedPopup} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title className='erp_modal_title'>PO Materials (Pre-Closed)</Modal.Title>
          <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleClosePreClosedPopup}></button></span>
        </Modal.Header>
        <Modal.Body>
          {preClosedPODetailsData.length > 0 ?
            <>
              <dl className="row mb-0">
                <div className="col-md-6 col-sm-12">
                  <dl className="row mb-0">
                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">Purchase Order No:</dt>
                    <dd className="col-sm-12 col-md-6 erp-form-label" id='txt_purchase_order_no_id'></dd>
                  </dl>
                </div>
                <div className="col-md-6 col-sm-12">
                  <dl className="row mb-0">
                    <dt className="col-sm-12 col-md-4 erp-form-label-md-lg">Purchase Order Date:</dt>
                    <dd className="col-sm-12 col-md-8 erp-form-label" id='txt_purchase_order_date_id'></dd>
                  </dl>
                </div>
              </dl>
              <dl className="row mb-0">
                <div className="col-md-6 col-sm-12">
                  <dl className="row mb-0">
                    <dt className="col-sm-6 col-md-6 erp-form-label-md-lg">Purchase Order Version:</dt>
                    <dd className="col-sm-6 col-md-6 erp-form-label" id='txt_purchase_order_version_id'></dd>  </dl>
                </div>
                <div className="col-md-6 col-sm-12">
                  <dl className="row mb-0">
                    <dt className="col-sm-12 col-md-4 erp-form-label-md-lg">Supplier Name:</dt>
                    <dd className="col-sm-12 col-md-8 erp-form-label" id='txt_supplier_name_id'></dd>
                  </dl>
                </div>
              </dl>

              <div className='row table-responsive'>
                {renderPreClosedPODetails}
              </div>
            </>
            :
            (
              <div className='row'>
                <div className="col-12  text-center">
                  <span className="erp_validation text-center" fontWeight="regular" color="error">
                    No Records Found...
                  </span>
                </div>
              </div>
            )}
        </Modal.Body>
        <Modal.Footer className='erp-modal-footer'>
          <MDButton type="button" className={`erp-gb-button ${preClosedPODetailsData.length > 0 ? 'display' : 'd-none'}`} variant="button"
            fontWeight="regular" onClick={() => FnPreClosedPODetails()}>Pre-Closed</MDButton>
        </Modal.Footer>
      </Modal>
      <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
      <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
    </>
  )
}

export default POListing;

