import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import ReactDOMServer from 'react-dom/server';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import $ from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from 'components/MDTypography';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";
import MDButton from "components/MDButton";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ExcelExport from "Features/Exports/ExcelExport";
import Select from 'react-select';
import MaterialSearchBar from "FrmGeneric/MaterialSearchBar";



function StorePurchaseStockReport() {
    const { requestfor } = useParams();
    const configConstants = ConfigConstants();
    const { COMPANY_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const navigate = useNavigate();

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [dt_from_date, setFromDt] = useState(today);
    const [dt_to_date, setToDt] = useState(today);
    const [reportType, setReportType] = useState('summary');
    const [category1Options, setCategory1Options] = useState([]);
    const [cmb_product_category1_id, setProduct_category1_id] = useState("All");
    const [productTypeOptions, setProductTypeOptions] = useState([]);
    const [cmb_product_type_id, setProduct_type_id] = useState();

    let productTypeComboRef = useRef(null);
    let category1ComboRef = useRef(null);

    const [category2Options, setCategory2Options] = useState([]);
    const [cmb_product_category2_id, setProduct_category2_id] = useState("All");
    let category2ComboRef = useRef(null);


    const [by_material, setByMaterial] = useState(false);
    const [search_material_id, setSearchMaterialId] = useState('');

    //Ref Hooks
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);
    const exlsExp = useRef();

    //data hook
    const [storeStockReportData, setStoreStockReportData] = useState([])

    useEffect(() => {
        const loadFiltersCombo = async () => {
            setIsLoading(true)
            await loadCombos();
            // await FnShowStoreStockData();
            setIsLoading(false)
        }
        loadFiltersCombo();
    }, [reportType, requestfor])


    useEffect(() => {
        const loadStockReport = async () => {
            setIsLoading(true)
            await FnShowStoreStockData();
            setIsLoading(false)
        }
        loadStockReport()
    }, [reportType])


    //key for get Request type header
    const requestTypeHeading = () => {
        debugger
        switch (requestfor) {
            case 'RM':
                return 'General Stores & Spares Stock Report';
            case 'PRM':
                return 'Raw material Stock Report';
            case 'BL':
                return '(Cotton Bales)';
            case 'GF':
                return '(Greige Fabric)';
            default:
                return 'Report';
        }
    }

    // ****-------Get Cost Center Data Function--------------***
    const loadCombos = async () => {
        try {
            //load product type options
            resetGlobalQuery();
            globalQuery.columns = ['product_type_id', 'product_type_name', 'product_type_short_name'];
            globalQuery.table = "sm_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const productTypeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const typesOpts = [
                { value: 'All', label: 'All', centerShortName: '' },
                ...productTypeList.map((productType) => ({ ...productType, value: productType.product_type_id, label: `${productType.product_type_name}` })),
            ];
            setProductTypeOptions(typesOpts);
            const selectedOption = typesOpts.find(item => item.product_type_short_name === requestfor);
            setProduct_type_id(selectedOption.product_type_id)
            productTypeComboRef.current = selectedOption;

            resetGlobalQuery();
            globalQuery.columns = ['product_category1_id', 'product_category1_name'];
            globalQuery.table = "sm_product_category1";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: selectedOption.product_type_id });
            const Category1List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const category1Opts = [
                { value: 'All', label: 'All', centerShortName: '' },
                ...Category1List.map((category1) => ({ ...category1, value: category1.product_category1_id, label: `${category1.product_category1_name}` })),
            ];
            setCategory1Options(category1Opts);
            setProduct_category1_id('All')

            const category2Opts = [
                { value: 'All', label: 'All' }
            ];
            setCategory2Options(category2Opts);
            setProduct_category2_id('All')

            setStoreStockReportData([])

        } catch (error) {
            console.log("error : ", error)
        }
    }

    // ***----------- Helping Functions.
    const goBack = () => { navigate("/Dashboard") }


    // Get Current DateTime
    const FnGetCurrentDateTime = () => {
        let currentDate = new Date(); // Get the current date and time
        // Format the current date and time as "DD-MM-YYYY HH:mm:ss"
        let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        return formattedDateTime;
    }
    // ****-------Validation Functions Starts--------------***
    const FnChkIsValidFilters = () => {
        const fromDate = new Date($("#dt_from_date").val());
        const toDate = new Date($("#dt_to_date").val());

        let isValid = true;

        if (!dt_from_date) {
            $('#error_dt_from_date').text('From Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_from_date').hide();
        }
        if (!dt_to_date) {
            $('#error_dt_to_date').text('To Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }
        if (dt_from_date && dt_to_date && fromDate > toDate) {
            $('#error_dt_from_date').text('From Date cannot be greater than To Date.');
            $('#error_dt_from_date').show();
            isValid = false;
        } else if (dt_from_date && dt_to_date) {
            $('#error_dt_from_date').hide();
        }
        return isValid;
    };

    // ****-------Validation Functions Ends--------------***

    // ****-------show data Function --------------***
    const FnShowStoreStockData = async (product_id = '') => {
        try {
            let isValidFilters = FnChkIsValidFilters();
            if (!isValidFilters) { return false; }
            setIsLoading(true)
            let commonIds = {};
            // Common Ids 
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            commonIds.product_type_id = cmb_product_type_id.toString();
            commonIds.category1_id = cmb_product_category1_id.toString();
            commonIds.category2_id = cmb_product_category2_id.toString();
            commonIds.product_id = product_id === '' ? search_material_id : product_id;
            console.log(commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getStoresStockReport/${reportType}`, requestOptions)
            const responce = await apiCall.json();
            console.log("stock Store Stock Report : ", responce);
            if (responce.success = 1) {
                // Get the issueReportData
                const issueReportData = responce.storeStockReportData;

                // Create an object to group data by cost_center_name
                const groupedData = {};

                // Loop through the data and group by cost_center_name
                issueReportData.forEach(item => {
                    const { product_category1_name } = item;

                    // If cost_center_name doesn't exist in the groupedData, initialize it
                    if (!groupedData[product_category1_name]) {
                        groupedData[product_category1_name] = {
                            rows: []
                        };
                    }

                    // Push the current item to the respective cost center's rows array
                    groupedData[product_category1_name].rows.push(item);

                });
                console.log(groupedData);
                setStoreStockReportData(groupedData);
            }

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }

    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintIssueReportSheet = useReactToPrint({

        content: () => {
            debugger
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: `Store Stock Report_${reportType === 'summary' ? `Summary` : `Details`}_(${dt_from_date}_to_${dt_to_date})`
    });

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Store Stock Report
                                {
                                    `(${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_from_date)} to ${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_to_date)})`
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    // ********* PrintOut Functionalities Ends. ****************************************

    // ********* ExportToExcel Functionalities Starts. ****************************************
    const addExcelFilter = (label, key, value, existingFilters) => {
        if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
    };

    const FnExportToExcel = async () => {
        // Means No Data For Export. 
        if (storeStockReportData && Object.keys(storeStockReportData).length === 0) { return null; }
        setIsLoading(true);
        try {
            const fromDate = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_from_date);
            const toDate = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_to_date);
            // Preparing Data For ExportFile.
            let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

            // Excel Headers;
            jsonToExportExcel['headings'] = {
                'ReportName': `${requestTypeHeading()} (${fromDate} to ${toDate})`,
                'CompanyName': COMPANY_NAME,
                'CompanyAddress': sessionStorage.getItem('companyAddress'),
            }

            let appliedFilters = {
                '0': `From-Date: ${fromDate || ""}`,
                '1': `From-Date: ${toDate || ""}`,
            };
            addExcelFilter(productTypeComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Product Type', appliedFilters);
            addExcelFilter(category1ComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Category1', appliedFilters);
            addExcelFilter(category2ComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Category2', appliedFilters);
            appliedFilters[Object.keys(appliedFilters).length] = `Generated On: ${FnGetCurrentDateTime()}`
            jsonToExportExcel['filtrKeyValue'] = appliedFilters;


            // Summary Table Columns and Accessors.
            const summaryColumns = [
                { "Headers": 'Sr. No.', "accessor": 'sr_no' },
                { "Headers": 'Category1 Name', "accessor": 'product_category1_name' },
                { "Headers": 'Category2 Name', "accessor": 'product_category2_name' },

                { "Headers": 'Opening Balance Qty', "accessor": 'opening_quantity' },
                { "Headers": 'Opening Balance Rate', "accessor": 'opening_average_batch_rate' },
                { "Headers": 'Opening Balance Value', "accessor": 'opening_total_material_value' },

                { "Headers": 'Purchase Balance Qty', "accessor": 'purchase_quantity' },
                { "Headers": 'Purchase Balance Rate', "accessor": 'purchase_average_batch_rate' },
                { "Headers": 'Purchase Balance Value', "accessor": 'purchase_total_material_value' },

                { "Headers": 'Issue Balance Qty', "accessor": 'issue_quantity' },
                { "Headers": 'Issue Balance Rate', "accessor": 'issue_average_batch_rate' },
                { "Headers": 'Issue Balance Value', "accessor": 'issue_total_material_value' },

                { "Headers": 'Closing Balance Qty', "accessor": 'closing_balance_quantity' },
                { "Headers": 'Closing Balance Rate', "accessor": 'closing_average_batch_rate' },
                { "Headers": 'Closing Balance Value', "accessor": 'closing_total_material_value' },
            ];

            // Details Table Columns and Accessors.
            const detailsColumns = [
                { "Headers": 'Sr. No.', "accessor": 'sr_no' },
                { "Headers": 'Date', "accessor": 'stock_date' },
                { "Headers": 'Category1 Name', "accessor": 'product_category1_name' },
                { "Headers": 'Category2 Name', "accessor": 'product_category2_name' },
                { "Headers": 'Material Code', "accessor": 'material_code' },
                { "Headers": 'Material Name', "accessor": 'material_name' },

                { "Headers": 'Opening Balance Qty', "accessor": 'opening_quantity' },
                { "Headers": 'Opening Balance Rate', "accessor": 'opening_average_batch_rate' },
                { "Headers": 'Opening Balance Value', "accessor": 'opening_total_material_value' },

                { "Headers": 'Purchase Balance Qty', "accessor": 'purchase_quantity' },
                { "Headers": 'Purchase Balance Rate', "accessor": 'purchase_average_batch_rate' },
                { "Headers": 'Purchase Balance Value', "accessor": 'purchase_total_material_value' },

                { "Headers": 'Issue Balance Qty', "accessor": 'issue_quantity' },
                { "Headers": 'Issue Balance Rate', "accessor": 'issue_average_batch_rate' },
                { "Headers": 'Issue Balance Value', "accessor": 'issue_total_material_value' },

                { "Headers": 'Closing Balance Qty', "accessor": 'closing_balance_quantity' },
                { "Headers": 'Closing Balance Rate', "accessor": 'closing_average_batch_rate' },
                { "Headers": 'Closing Balance Value', "accessor": 'closing_total_material_value' },
            ];

            jsonToExportExcel['columns'] = reportType === 'summary' ? summaryColumns : detailsColumns;

            let currentIndex = 0; // To track row position in allData
            {
                Object.keys(storeStockReportData).map((categoryName) => {
                    const { rows } = storeStockReportData[categoryName];
                    // Iterate through each row and assign it to allData
                    rows.map((row, index) => {
                        jsonToExportExcel['allData'][currentIndex] = {
                            ...row,
                            sr_no: currentIndex + 1 // Maintain global serial number
                        };
                        currentIndex++; // Increment the index for each new row
                    });
                })
            }
            // Call Common function for ExportToExcel
            await exlsExp.current.excel(jsonToExportExcel, `Issue_${reportType === 'summary' ? 'summary' : 'details'}_Report_${fromDate}_to_${toDate}`);
        } catch (error) {
            console.log(error);

        } finally {
            setIsLoading(false);
        }

    }
    // ********* ExportToExcel Functionalities Ends. ****************************************

    const comboOnChange = async (key) => {

        try {
            switch (key) {
                case 'category1':
                    let category1 = category1ComboRef.current.value;
                    if (category1 != 'All') {
                        resetGlobalQuery();
                        globalQuery.columns = ['product_category2_id', 'product_category2_name'];
                        globalQuery.table = "sm_product_category2";
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1 });
                        const Category2List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        const category2Opts = [
                            { value: 'All', label: 'All' },
                            ...Category2List.map((category2) => ({ ...category2, value: category2.product_category2_id, label: `${category2.product_category2_name}` })),
                        ];
                        setCategory2Options(category2Opts);
                        setProduct_category2_id('All')
                    } else {
                        const category2Opts = [
                            { value: 'All', label: 'All' }
                        ];
                        setCategory2Options(category2Opts);
                        setProduct_category2_id('All')
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);

        }
    }
    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_code'];

    const getMaterialBySearch = async (selectedData) => {
        if (selectedData !== null) {
            try {
                setIsLoading(true)
                let { product_id, product_name, product_code } = selectedData[0];
                let material_name = product_name;
                let material_code = product_code;
                let material_id = product_id;
                console.log("searched Material:  ", material_name, ' : ', material_code, ' : ', material_id);
                debugger
                if (by_material) {
                    setSearchMaterialId(product_id)
                    await FnShowStoreStockData(product_id);
                    $('#error_selectMaterialRecords_id').hide();
                } else {
                    $('#error_selectMaterialRecords_id').text('Please fill checkbox first').show();
                }
            } catch (error) {
                console.error("Error in Search Material: ", error);
                setSearchMaterialId('')
                setIsLoading(false)
            } finally {
                setIsLoading(false)
            }
        } else {
            setStoreStockReportData([]);
        }
    }



    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <ExcelExport ref={exlsExp} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card mt-4'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>{requestTypeHeading()}</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className='row p-1'>
                            <div className="erp_form_radio">
                                <div className="fCheck">
                                    <Form.Check
                                        className="erp_radio_button erp-form-label-md"
                                        label="Summary"
                                        type="radio"
                                        value="summary"
                                        name="summaryOrDetails"
                                        onChange={(e) => setReportType(e.target.value)}
                                        defaultChecked
                                    />
                                </div>
                                <div className="sCheck">
                                    <Form.Check
                                        className="erp_radio_button erp-form-label-md"
                                        label="Details"
                                        value="details"
                                        type="radio"
                                        onChange={(e) => setReportType(e.target.value)}
                                        name="summaryOrDetails"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={productTypeComboRef}
                                            inputId="cmb_product_type_id"
                                            value={productTypeOptions.find(option => option.value === cmb_product_type_id) || null}
                                            options={productTypeOptions}
                                            onChange={(selectedOption) => {
                                                setProduct_type_id(selectedOption.value)
                                                productTypeComboRef.current = selectedOption;
                                                // comboOnChange('category1');
                                            }}
                                            placeholder="Search for product type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            isDisabled={true}

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDt(e.target.value); FnChkIsValidFilters(); }} />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDt(e.target.value); FnChkIsValidFilters(); }} />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row p-1'>
                            {/* Categories COMBO BOX*/}
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Category 1:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={category1ComboRef}
                                            inputId="cmb_product_category1_id"
                                            value={category1Options.find(option => option.value === cmb_product_category1_id) || null}
                                            options={category1Options}
                                            onChange={(selectedOption) => {
                                                setProduct_category1_id(selectedOption.value)
                                                category1ComboRef.current = selectedOption;
                                                comboOnChange('category1');
                                            }
                                            }
                                            placeholder="Search for Category1..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Category 2:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={category2ComboRef}
                                            inputId="cmb_product_category2_id"
                                            value={category2Options.find(option => option.value === cmb_product_category2_id) || null}
                                            options={category2Options}
                                            onChange={(selectedOption) => {
                                                setProduct_category2_id(selectedOption.value)
                                                category2ComboRef.current = selectedOption;
                                                comboOnChange('category2');
                                            }
                                            }
                                            placeholder="Search for Category2..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className={`row ${reportType === 'summary' ? 'd-none' : 'display'}`}>
                                    <div className="col-sm-4">
                                        <input type='checkbox' class="me-1" name="selectMaterialRecords" id="selectMaterialRecords" onChange={(e) => { e.target.checked ? setByMaterial(true) : setByMaterial(false); setSearchMaterialId('') }} /> <label class="erp-form-label pb-1">Search By Material </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <MaterialSearchBar getSearchData={getMaterialBySearch} formIdForValidate="" requiredCols={requiredCols} product_type_id={cmb_product_type_id} product_category1_id={cmb_product_category1_id == 'All' ? 0 : cmb_product_category1_id} product_category2_id={cmb_product_category2_id == 'All' ? 0 : cmb_product_category2_id} />
                                        <MDTypography variant="button" id="error_selectMaterialRecords_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>
                            <div className="row justify-content-center mt-2">
                                <div className="col text-center">
                                    <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowStoreStockData(); }} variant="button" fontWeight="regular">show Data</MDButton>
                                </div>
                            </div>
                        </div>
                        {
                            storeStockReportData && Object.keys(storeStockReportData).length === 0
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                : <>
                                    <hr />
                                    <div className="row py-1">
                                        <div className="text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }}
                                            >Export Excel</MDButton>

                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                onClick={(e) => { FnPrintIssueReportSheet(); }}
                                            >Print</MDButton>
                                        </div>
                                    </div>
                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                <StoreReportTbl
                                                    storeStockReportData={storeStockReportData}
                                                    validateNumberDateInput={validateNumberDateInput}
                                                    reportType={reportType}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                {
                                    storeStockReportData && Object.keys(storeStockReportData).length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2"
                                                onClick={(e) => { FnPrintIssueReportSheet(); }} >Print</MDButton>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    {/* Card's Body Ends*/}
                </div>
                {/* Card Ends*/}


            </DashboardLayout>
        </>
    )
}

export default StorePurchaseStockReport




export const StoreReportTbl = ({
    storeStockReportData,
    validateNumberDateInput,
    reportType
}) => {

    return (
        <>
            <style>
                {`
                    @media print {
                        .print-content {
                            padding: 0;
                            box-sizing: border-box;
                        }
                        @page {
                            margin: 0;
                            size: legal landscape;
                            margin: 1%;
                            border: 2px solid black;
                        }
                        html, body {
                            width: 100%;
                            height: 100%;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .erp_table {
                        font-size: 3px !important; /* Default font size */
                    }
                    .erp_table_th, .erp_table_td {
                        border: 1px solid black;
                        padding-top: 2px !important;
                        padding-bottom: 2px !important;
                    }
                `}
            </style>

            <Table id="issueReportTableId" className="erp_table" style={{ whiteSpace: 'normal', tableLayout: 'fixed' }}>
                <thead className="erp_table_head table-secondary text-center">
                    <tr style={{ whiteSpace: 'normal' }}>
                        <th className="erp_table_th align-middle" style={{ width: "70px" }} rowSpan={2}>Sr. No</th>
                        <th className={`erp_table_th align-middle  ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: "100px" }} rowSpan={2}>Date</th>
                        <th className="erp_table_th align-middle" style={{ width: "150px" }} rowSpan={2}>Category1 Name</th>
                        <th className="erp_table_th align-middle" style={{ width: "150px" }} rowSpan={2}>Category2 Name</th>
                        <th className={`erp_table_th align-middle  ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: "100px" }} rowSpan={2}>Material Code</th>
                        <th className={`erp_table_th align-middle  ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: '360px' }} rowSpan={2}>Material Name</th>
                        <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Opening Balance</th>
                        <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Purchase</th>
                        <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Issue</th>
                        <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Closing Balance</th>
                    </tr>
                    <tr style={{ whiteSpace: 'normal' }}>
                        <th className="erp_table_th align-middle" >Qty</th>
                        <th className="erp_table_th align-middle" >Rate</th>
                        <th className="erp_table_th align-middle" >Value</th>
                        <th className="erp_table_th align-middle" >Qty</th>
                        <th className="erp_table_th align-middle" >Rate</th>
                        <th className="erp_table_th align-middle" >Value</th>
                        <th className="erp_table_th align-middle" >Qty</th>
                        <th className="erp_table_th align-middle" >Rate</th>
                        <th className="erp_table_th align-middle" >Value</th>
                        <th className="erp_table_th align-middle" >Qty</th>
                        <th className="erp_table_th align-middle" >Rate</th>
                        <th className="erp_table_th align-middle" >Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(storeStockReportData).map((categoryName) => {
                        const { rows, total } = storeStockReportData[categoryName];
                        return (
                            <React.Fragment key={categoryName}>
                                {rows.map((row, index) => (
                                    <tr rowindex={index}>
                                        <td className="erp_table_td text-end" > {index + 1} </td>
                                        <td className={`erp_table_td  ${reportType === 'summary' ? 'd-none' : 'display'}`}> {validateNumberDateInput.current.formatDateToDDMMYYYY(row.stock_date)} </td>
                                        <td className={`erp_table_td `} style={{ whiteSpace: 'normal' }}> {row.product_category1_name} </td>
                                        <td className={`erp_table_td `} style={{ whiteSpace: 'normal' }}> {row.product_category2_name} </td>
                                        <td className={`erp_table_td  ${reportType === 'summary' ? 'd-none' : 'display'}`}> {row.material_code} </td>
                                        <td className={`erp_table_td  ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ whiteSpace: 'normal' }}> {row.material_name} </td>
                                        <td className="erp_table_td text-end"> {row.opening_quantity} </td>
                                        <td className="erp_table_td text-end">
                                            {validateNumberDateInput.current.decimalNumber(
                                                row.opening_average_batch_rate,
                                                4
                                            )}
                                        </td>
                                        <td className="erp_table_td text-end">
                                            {validateNumberDateInput.current.decimalNumber(
                                                row.opening_total_material_value,
                                                4
                                            )}
                                        </td>
                                        <td className="erp_table_td text-end"> {row.purchase_quantity} </td>
                                        <td className="erp_table_td text-end">

                                            {validateNumberDateInput.current.decimalNumber(
                                                row.purchase_average_batch_rate,
                                                4
                                            )}
                                        </td>
                                        <td className="erp_table_td text-end">
                                            {validateNumberDateInput.current.decimalNumber(
                                                row.purchase_total_material_value,
                                                4
                                            )}
                                        </td>
                                        <td className="erp_table_td text-end"> {row.issue_quantity} </td>
                                        <td className="erp_table_td text-end">
                                            {validateNumberDateInput.current.decimalNumber(
                                                row.issue_average_batch_rate,
                                                4
                                            )}
                                        </td>
                                        <td className="erp_table_td text-end">
                                            {validateNumberDateInput.current.decimalNumber(
                                                row.issue_total_material_value,
                                                4
                                            )}
                                        </td>
                                        <td className="erp_table_td text-end"> {row.closing_balance_quantity} </td>
                                        <td className="erp_table_td text-end">

                                            {validateNumberDateInput.current.decimalNumber(
                                                row.closing_average_batch_rate,
                                                4
                                            )}
                                        </td>
                                        <td className="erp_table_td text-end">
                                            {validateNumberDateInput.current.decimalNumber(
                                                row.closing_total_material_value,
                                                4
                                            )}
                                        </td>
                                    </tr>

                                ))}

                                <tr className='table-light'>
                                    <td className="erp_table_td align-middle" colSpan={` ${reportType === 'summary' ? '3' : '6'}`}><strong>Totals: </strong> </td>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {rows.reduce((sum, summary) => sum + summary.opening_quantity, 0)}
                                    </th>
                                    <td className="erp_table_td" ></td>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {validateNumberDateInput.current.decimalNumber(
                                            rows.reduce((sum, summary) => sum + summary.opening_total_material_value, 0),
                                            4
                                        )}
                                    </th>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {rows.reduce((sum, summary) => sum + summary.purchase_quantity, 0)}
                                    </th>
                                    <td className="erp_table_td" ></td>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {validateNumberDateInput.current.decimalNumber(
                                            rows.reduce((sum, summary) => sum + summary.purchase_total_material_value, 0),
                                            4
                                        )}
                                    </th>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {rows.reduce((sum, summary) => sum + summary.issue_quantity, 0)}
                                    </th>
                                    <td className="erp_table_td" ></td>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {validateNumberDateInput.current.decimalNumber(
                                            rows.reduce((sum, summary) => sum + summary.issue_total_material_value, 0),
                                            4
                                        )}
                                    </th>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {rows.reduce((sum, summary) => sum + summary.closing_balance_quantity, 0)}
                                    </th>
                                    <td className="erp_table_td" ></td>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {validateNumberDateInput.current.decimalNumber(
                                            rows.reduce((sum, summary) => sum + summary.closing_total_material_value, 0),
                                            4
                                        )}
                                    </th>
                                </tr>
                            </React.Fragment>
                        );
                    })};
                </tbody>
            </Table>
        </>
    );
};