import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

function SalaryBankListRegister() {

  // Some Static Hardcoded Values for the Banks; Show the only particular banks. (Told by prashant sir on whats'app on 24/07/2024)
  // For Pashupati Cott Spinn Ltd Show only Punjab banks and Other Transfer                   
  // const pnbBankIdSpinnig = 10;  // * company_BasedID id -> 10
  // const cashBankIdSpinning = 1;

  // For Pashupati Text Spinn Export LLP Show only  Axis Banks And Other  and Other Transfer  
  // const axisBankIDTexSpin = 49; // * company_BasedID id -> 49 
  // const cashBankIdTexSpin = 37;

  // For Pashupati Ginning Show only only Punjab banks and Other Transfer                     
  // const pnbBankIdGinning = 68;  // * company_BasedID id -> 68
  // const cashBankIdGinning = 59;

  // This companyIds Are company 1 based only; because Prashant Sir updated the employee's data.
  const pnbBankId = 10;
  const axisBankId = 13;
  const cashBankId = 1;

  // Global Constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, FINANCIAL_SHORT_NAME, COMPANY_NAME, } = configConstants;

  // UseRefs Hooks.
  const comboDataFunc = useRef();
  const exlsExp = useRef();

  // Ref Hooks For Print-Out.    
  const printComponentRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);

  // ****** navigate
  const navigate = useNavigate();

  const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
  const [monthOpts, setMonthOpts] = useState([
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ]);
  const [yearOpts, setYearOpts] = useState(
    Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
      .map((year) => ({ label: year, value: year }))
  );
  const [employeeOpts, setEmployeeOpts] = useState([]);
  const [salaryBankOpts, setSalaryBankOpts] = useState([]);
  const [companyBankDtl, setCompanyBankDtl] = useState([])
  // const [companyBankDtl, setCompanyBankDtl] = useState({
  //   company_branch_bank_name: '',
  //   company_branch_bank_branch_name: '',
  //   company_branch_bank_account_no: '',
  //   company_branch_bank_ifsc_code: '',
  //   bank_id: '',
  // });

  // Input field's Hooks;
  const [cmb_salary_month, setSalaryMonth] = useState('');
  const [cmb_salary_year, setSalaryYear] = useState('');
  const [cmb_salary_bank_id, setSalaryBankId] = useState(null);
  const [cmb_employee_type_id, setEmployeeTypeId] = useState('');
  const [cmb_employee_id, setEmployeeId] = useState('');
  const [cmb_company_bank_id, setCompanybankId] = useState(null);

  const [employeeSalaryDetails, setEmployeeSalaryDetails] = useState([]);
  const [departmentOpts, setDepartmentOpts] = useState([]);
  const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
  const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.

  const [cmb_department_id, setDepartmentId] = useState('');
  const [cmb_sub_department_id, setSubDepartmentId] = useState('');
  // UseRefs Hooks For Searchable combos.
  let salaryMonthComboRef = useRef(null);
  let salaryYearComboRef = useRef(null);
  let salaryBankComboRef = useRef(null);
  let employeeTypesComboRef = useRef(null);
  let employeeComboRef = useRef(null);
  let salaryCompanyBankComboRef = useRef(null);
  let departmentComboRef = useRef(null);
  let subDepartmentComboRef = useRef(null);
  // Helping hooks;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadDataOnload = async () => {
      setIsLoading(true);
      await FnFillCombos();
      setIsLoading(false);
    }
    loadDataOnload();
  }, []);

  const FnFillCombos = async () => {
    debugger
    try {
      // Set Default Year.
      let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
      setSalaryYear(defaultYear.value);
      salaryYearComboRef.current = defaultYear;
      // Set Default Month.
      let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
      setSalaryMonth(defaultMonth.value);
      salaryMonthComboRef.current = defaultMonth;


      // Load employee types
      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
      globalQuery.table = "amv_properties"
      globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdEmplTypes => {
          // setEmployeeProps(rcvdEmplTypes);
          let emplTypes = rcvdEmplTypes
            .filter(prop => prop.properties_master_name === 'EmployeeType')
            .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
          emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
          setEmployeeTypesOpts(emplTypes);
        });

      // Load Banks
      resetGlobalQuery();
      globalQuery.columns = ["bank_id", "bank_name"];
      globalQuery.table = "cm_banks_List"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // const rcvdBanks = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      // setSalaryBankOpts(rcvdBanks);
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdBanks => {
          rcvdBanks = rcvdBanks
            ?.map(prop => ({ ...prop, value: prop.bank_id, label: prop.bank_name }));

          // Show the only some banks in bank combo;
          // if (parseInt(COMPANY_ID) === 1 || parseInt(COMPANY_ID) === 3) {
          //   rcvdBanks = rcvdBanks.filter(prop => [pnbBankId, cashBankId].includes(prop.bank_id))
          // } else if (parseInt(COMPANY_ID) === 2) {
          //   rcvdBanks = rcvdBanks.filter(prop => [axisBankId, cashBankId].includes(prop.bank_id))
          // }
          rcvdBanks.push({ bank_id: 0, value: 0, label: "Others", bank_name: "Others" })
          setSalaryBankOpts(rcvdBanks);
        });

      // Load Current Company Bank Details. from cmv_company_banks
      resetGlobalQuery();
      globalQuery.columns = ["company_branch_bank_name", "company_branch_bank_branch_name", "company_branch_bank_account_no", "company_branch_bank_ifsc_code", "bank_id"];
      globalQuery.table = "cmv_company_banks"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdBankDtls => {
          rcvdBankDtls = rcvdBankDtls
            ?.map(prop => ({ ...prop, value: prop.bank_id, label: prop.company_branch_bank_name }));

          rcvdBankDtls.push({ bank_id: 0, value: 0, label: "Others", company_branch_bank_name: "Others" })
          setCompanyBankDtl(rcvdBankDtls);
        });
      // comboDataFunc.current.fillFiltersCombo(globalQuery)
      //   .then(rcvdBankDtls => {
      //     // console.log('rcvdBankDtls: ', rcvdBankDtls)
      //     let debitACDtl = null;
      //     if (parseInt(COMPANY_ID) === 1 || parseInt(COMPANY_ID) === 3) {
      //       debitACDtl = rcvdBankDtls.find(prop => pnbBankId === prop.bank_id) || null;
      //     } else if (parseInt(COMPANY_ID) === 2) {
      //       debitACDtl = rcvdBankDtls.find(  prop => axisBankId === prop.bank_id) || null;
      //     }
      //     console.log('currentBankDtl: ', debitACDtl)

      //     setCompanyBankDtl(
      //       debitACDtl !== null
      //         ? debitACDtl
      //         : {
      //           company_branch_bank_name: '',
      //           company_branch_bank_branch_name: '',
      //           company_branch_bank_account_no: '',
      //           company_branch_bank_ifsc_code: '',
      //           bank_id: '',
      //         }
      //     );

      //   });

      // Load Department & Sub-Department
      resetGlobalQuery();
      globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
      globalQuery.table = "cm_department"
      globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdDepts => {
          const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
            if (department.department_type === "M") {
              acc.mainDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            } else if (department.department_type === "S") {
              acc.subDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            }
            return acc;
          }, { mainDepartments: [], subDepartments: [] });
          setAllDepartmentsList([...mainDepartments, ...subDepartments]);

          mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
          setDepartmentOpts(mainDepartments);
        });

    } catch (error) {
      console.log("error on filling combos: ", error)
      navigate('/Error')
    }
  }

  const FnComboOnChange = async (comboName) => {
    try {
      switch (comboName) {
        case "EmployeeType":
          await FnSetDefaultData();
          break;
        case "Bank":
          await FnSetDefaultData();
          break;
        case "Department":

          let selectedDepartment = departmentComboRef.current.department_id;
          const subDepartmentList = allDepartmentsList.filter(department =>
            (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
          );
          subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentList);
          // remove the already selected sub-department
          subDepartmentComboRef.current = null;
          setSubDepartmentId('');
          // await FnLoadEmployees();
          await FnSetDefaultData();
          break;

        case "SubDepartment":
          await FnSetDefaultData();
          break;

        default:
          break;
      }

    } catch (error) {
      console.log("error on combos change: ", error)
      navigate('/Error')
    }
  }

  const FnSetDefaultData = async () => {
    try {
      // Filters for load employee query.
      let filterConditions = [
        { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
        { field: "is_delete", operator: "=", value: 0 },
      ];

      let selectedSalaryBank = salaryBankComboRef.current?.bank_id;

      // First Set Default Department.
      let selectedDepartment = departmentComboRef.current?.department_id;
      if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
        filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
      } else if (selectedDepartment === '' || selectedDepartment === undefined || selectedDepartment === null) {
        let defaultDept = departmentOpts.find(department => department.value == 'All');
        setDepartmentId(defaultDept.value);
        departmentComboRef.current = defaultDept;

        // Also Load All Sub-Department
        const subDepartmentList = allDepartmentsList.filter(department => department.department_type === "S");
        subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
        setSubDepartmentOpts(subDepartmentList);
        subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
        setSubDepartmentId('All');
        selectedDepartment = '';
      }

      // Second Set Default Sub-Department.
      let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
      if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
        filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
      } else {
        const subDepartmentList = allDepartmentsList.filter(department =>
          (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
        );
        subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
        setSubDepartmentOpts(subDepartmentList);
        subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
        setSubDepartmentId('All');
      }
      // Set Default Employee Type.
      let selectedEmplType = employeeTypesComboRef.current.field_name;
      if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
        filterConditions.push({ field: "employee_type", operator: "=", value: selectedEmplType });
      } else {
        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All' || employee.field_name == 'All');
        setEmployeeTypeId(defaultEmplType.value);
        employeeTypesComboRef.current = defaultEmplType;
        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
          ?.map(item => item?.field_name)
          ?.filter(id => id !== undefined && id !== null && id !== '');;
        filterConditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
      }
      if (selectedSalaryBank !== 0) { // Means selected the other bank;
        filterConditions.push({ field: "bank_id1", operator: "=", value: selectedSalaryBank, })
      }

      resetGlobalQuery();
      globalQuery.columns = ["employee_id", "employee_code", "employee_name"];
      globalQuery.table = "cmv_employee"
      globalQuery.conditions = filterConditions;
      let getEmployeeList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      if (getEmployeeList.length > 0) {
        getEmployeeList = getEmployeeList?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
        getEmployeeList.unshift({ employee_id: '', value: 'All', label: 'All' });
        setEmployeeOpts(getEmployeeList);
        setEmployeeId('All');
        employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
      } else {
        setEmployeeOpts([]);
        setEmployeeId('');
        employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  // ***----------- Helping Functions.
  const goBack = () => { navigate("/Dashboard") }

  // Get Current DateTime
  const FnGetCurrentDateTime = () => {
    let currentDate = new Date(); // Get the current date and time
    // Format the current date and time as "DD-MM-YYYY HH:mm:ss"
    let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
    return formattedDateTime;
  }
  // ***----------- Helping Functions Ends.


  // ****-------Get Employee Salary Details Functions Starts--------------***
  const FnShowSalaryData = async () => {
    debugger
    try {
      let isValidFilters = await FnChkIsValidFilters();
      if (!isValidFilters) { return false; }

      // *** Means Filters are valid then filter data as per the filters.
      setIsLoading(true);


      // *** Apply Filters Conditions;
      let selectedEmplType = employeeTypesComboRef.current.field_name;
      let selectedEmployee = employeeComboRef.current.employee_id;
      let selectedSalaryBank = salaryBankComboRef.current?.bank_id;


      let filterConditions = [
        { field: "htsal.salary_year", operator: "=", value: cmb_salary_year },
        { field: "htsal.salary_month", operator: "=", value: cmb_salary_month },
        { field: "htsal.net_salary", operator: ">", value: 0 },
        { field: "empl.is_active", operator: "=", value: 1 },
        { field: "htsal.company_id", operator: "=", value: parseInt(COMPANY_ID) },
        { field: "htsal.is_delete", operator: "=", value: 0 },
        { field: "empl.bank_id1", operator: "!=", value: 1 },
      ];


      // Check it selected one employee or not? 
      if (selectedEmployee !== undefined && selectedEmployee !== null && selectedEmployee !== '') {
        filterConditions.push({ field: "htsal.employee_id", operator: "=", value: selectedEmployee });

      } else {
        // Means load data without employee filter.
        // If Valid Then set the default data if not selected any filter.
        await FnSetDefaultData();
        if (selectedEmployee === '') {
          const employeeIds = employeeOpts
            .filter(emp => emp.employee_id !== "") // Remove entries where employee_id is an empty string
            .map(emp => emp.employee_id);

          if (employeeIds.length === 1) {
            // If only one employee_id, use "=" operator with a single value
            filterConditions.push({
              field: "empl.employee_id",
              operator: "=",
              value: employeeIds[0] // Single value, no need for join
            });
          } else if (employeeIds.length > 1) {
            // If multiple employee_ids, use "IN" with a comma-separated string
            filterConditions.push({
              field: "empl.employee_id",
              operator: "IN",
              values: employeeIds.map(id => (isNaN(id) ? `"${id}"` : id)) // Enclose non-numeric IDs in quotes
            });
          }
        }

        if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
          filterConditions.push({ field: "empl.employee_type", operator: "=", value: selectedEmplType });
        }
      }

      resetGlobalQuery();
      globalQuery.columns = [
        "empl.employee_code", "empl.employee_name",
        "empl.bank_name1", "empl.account_name1", "empl.account_no1", "empl.ifsc_code1",
        "htsal.net_salary"
      ];
      globalQuery.table = "ht_salary_summary htsal";
      globalQuery.conditions = [...filterConditions];

      if (selectedSalaryBank !== 0) { // Means selected the other bank;
        globalQuery.conditions.push({ field: "empl.bank_id1", operator: "=", value: selectedSalaryBank, })
      } else {    // Means selected any valid bank or cash bank;
        let bankIdsNotIn = companyBankDtl.filter(bank => bank?.bank_id !== 0).map(Fbank => Fbank?.bank_id)
        globalQuery.conditions.push({ field: "empl.bank_id1", operator: "NOT IN", values: bankIdsNotIn, })
      }

      // Managed the company_based bank details into the employee_master;
      // if([cashBankIdSpinning, cashBankIdTexSpin, cashBankIdGinning].includes((selectedSalaryBank))){ // Means Selected the cash bank;
      //   globalQuery.conditions.push({ field: "empl.bank_id1", operator: "IN", values: [cashBankIdSpinning, cashBankIdTexSpin, cashBankIdGinning], })

      // } else if([pnbBankIdSpinnig, axisBankIDTexSpin, pnbBankIdGinning].includes((selectedSalaryBank))){ // Means Selected the Not a cash and other;
      //   globalQuery.conditions.push({ field: "empl.bank_id1", operator: "=", value: selectedSalaryBank, })

      // } else {  // means selected others
      //   let bankIdsNotIn = salaryBankOpts.filter(bank => bank?.bank_id !== 0).map(Fbank => Fbank?.bank_id)
      //   globalQuery.conditions.push({ field: "empl.bank_id1", operator: "NOT IN", values: [...bankIdsNotIn, cashBankIdSpinning, cashBankIdTexSpin, cashBankIdGinning] })

      // }

      globalQuery.joins = [
        {
          "table": `cmv_employee empl`,
          "type": "LEFT",
          "on": [
            { "left": "empl.employee_id", "right": "htsal.employee_id", },
            { "left": "empl.company_id", "right": "htsal.company_id", },
          ]
        },
      ]
      globalQuery.orderBy = ['empl.department_id Asc', 'empl.sub_department_id Asc', 'empl.employee_code Asc',];
      let getSalaryDetails = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      if (getSalaryDetails.length > 0) {
        getSalaryDetails = getSalaryDetails.map((record, index) => ({
          sr_no: index + 1,
          ...record,
        }));

        setEmployeeSalaryDetails(getSalaryDetails);
        // console.table(getSalaryDetails);
      } else {
        setEmployeeSalaryDetails([]);
      }

    } catch (error) {
      console.log("error on combos change: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false);
    }
  }


  const FnRenderSalaryDetailsTbl = useMemo(() => {
    if (employeeSalaryDetails.length === 0) { return null; }

    return (
      <>
        <style>
          {`
                @media print {
                    .print-content {
                        padding: 0;
                        box-sizing: border-box;
                    }
                    @page {
                        margin: 0;
                        size: A4;
                        margin: 2%;
                        border: 2px solid black;
                    }
                    html, body {
                        width: 100%;
                        height: 100%;
                    }
                    body {
                        margin: 0;
                        padding: 0;
                    }
                }
                .erp_table {
                    font-size: 3px !important; /* Default font size */
                }
                .erp_table_th, .erp_table_td {
                    border: 1px solid black;
                    padding-top: 2px !important;
                    padding-bottom: 2px !important;
                }
            `}
        </style>

        <Table className="erp_table" id='MonthlySalaryDtlsTbl' style={{ whiteSpace: 'normal' }} ref={printComponentRef}>
          <thead className="erp_table_head thead-light text-center" style={{ whiteSpace: 'normal' }} >
            <tr style={{ whiteSpace: 'normal' }}>
              <th className="erp_table_th">Sr. No.</th>
              {/* <th className="erp_table_th">Employee Code</th>
              <th className="erp_table_th">Employee Name</th> */}
              <th className="erp_table_th">Name As per Bank</th>
              <th className="erp_table_th">Bank Name</th>

              <th className="erp_table_th">Account No.</th>
              <th className="erp_table_th">IFSC Code</th>
              <th className="erp_table_th">Salary Amount</th>
            </tr>
          </thead>

          <tbody>
            {
              employeeSalaryDetails?.map((salaryRecord, index) => {
                return (
                  <tr key={index} rowIndex={index}>
                    <td className="erp_table_td text-end">{index + 1}</td>
                    {/* <td className="erp_table_td">{salaryRecord.employee_code}</td>
                    <td className="erp_table_td">{salaryRecord.employee_name}</td> */}
                    <td className="erp_table_td">{salaryRecord.account_name1}</td>
                    <td className="erp_table_td">{salaryRecord.bank_name1}</td>

                    <td className="erp_table_td">{salaryRecord.account_no1}</td>
                    <td className="erp_table_td">{salaryRecord.ifsc_code1}</td>
                    <td className="erp_table_td text-end">{salaryRecord.net_salary}</td>
                  </tr>
                )
              })
            }

            {
              // Footer Summary Row.
              <tr className='table-light' key={employeeSalaryDetails.length + 1} rowIndex={employeeSalaryDetails.length + 1}>
                <th className="erp_table_th" colSpan={5}>Summary Totals</th>
                <th className="erp_table_th text-end" >
                  {employeeSalaryDetails.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2)}
                </th>
              </tr>


            }
            <tr>
              <td colSpan={6} className="erp_table_td align-bottom" style={{ fontSize: "13px", marginTop: "10px" }}>
                <div className='row  text-center' style={{ marginTop: "25px" }}>
                  <div className='col-4 text-center'>HR</div>
                  <div className='col-4 text-center'>HOD</div>
                  <div className='col-4 text-center'>MD</div>
                </div>
              </td>
            </tr>

          </tbody>
        </Table>
      </>
    )
  }, [employeeSalaryDetails])

  // ****-------Get Employee Salary Details Functions Ends--------------***



  // ****-------Validation Functions Starts--------------***
  // Function for validate the fields at the time of button click;
  const FnChkIsValidFilters = async () => {
    if (cmb_salary_year === '' || cmb_salary_year === null) {
      $('#error_cmb_salary_year').text('Please select the atleast one...!')
      $('#error_cmb_salary_year').show();
      return false;
    } else {
      $('#error_cmb_salary_year').hide();
    }

    if (cmb_salary_month === '' || cmb_salary_month === null) {
      $('#error_cmb_salary_month').text('Please select the atleast one...!')
      $('#error_cmb_salary_month').show();
      return false;
    } else {
      $('#error_cmb_salary_month').hide();
    }

    if (cmb_salary_bank_id === '' || cmb_salary_bank_id === null) {
      $('#error_cmb_salary_bank_id').text('Please select the atleast one...!')
      $('#error_cmb_salary_bank_id').show();
      return false;
    } else {
      $('#error_cmb_salary_bank_id').hide();
    }
    return true;
  }

  // Function for validate the fields on change
  const isEmpty = str => {
    if (typeof str === 'number' && Number.isInteger(str)) { str = str.toString(); }
    return str === undefined || str === null || str.trim() !== '';
  };

  const FnValidateFields = () => {
    setEmployeeSalaryDetails([]);
    if (isEmpty(salaryYearComboRef?.current?.value || null)) { $('#error_cmb_salary_year').hide(); }
    if (isEmpty(salaryMonthComboRef.current?.value || null)) { $('#error_cmb_salary_month').hide(); }
    if (isEmpty(salaryBankComboRef.current?.value || '')) { $('#error_cmb_salary_bank_id').hide(); }
    if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
    if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    if (isEmpty(salaryCompanyBankComboRef.current?.value || '')) { $('#error_cmb_salary_company_bank_id').hide(); }

  }

  // ****-------Validation Functions Ends--------------***

  // ********* PrintOut Functionalities Starts. ****************************************
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const FnPrintReport = useReactToPrint({
    content: () => {
      const headerHtml = printOutHeader(); // Call function to get header HTML

      const contentWrapper = document.createElement('div');
      contentWrapper.className = 'printable-content';
      contentWrapper.innerHTML = headerHtml; // Add generated header HTML

      // Append the main content to the wrapper
      const mainContentClone = printComponentRef.current.cloneNode(true);
      contentWrapper.appendChild(mainContentClone);

      return contentWrapper;
    },
    onBeforePrint: () => {
      // Apply the desired font size before printing
      if (printComponentRef.current) {
        const table = printComponentRef.current.querySelector('.erp_table');
        if (table) {
          table.style.fontSize = '7px'; // Set your desired font size
        }
      }
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
    documentTitle: `${COMPANY_NAME} Salary Bank List For ${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`
  });

  const printOutHeader = () => {
    debugger
    return ReactDOMServer.renderToString(
      <>
        <div className='px-0 border border-dark'> {/* border border-dark */}
          <div className='row mb-0 p-1 border-bottom border-dark'>
            <div className="col-sm-1">
              <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
            </div>
            <div className="col-sm-11">
              <div className='erp-invoice-print-label text-center'>
                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
              </div>
              <div className='erp-invoice-print-label-lg text-center'> Salary Bank List </div>
              <div className='erp-invoice-print-label-lg text-center'>
                For Month: {salaryMonthComboRef.current?.label}   Year: {salaryYearComboRef.current?.value}
              </div>
            </div>
          </div>
          <dl className="row pb-0 mb-0 px-1">
            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}>Bank Name:</dt>
                {/* <dd className='col-sm-9 erp-invoice-print-label'> {companyBankDtl?.company_branch_bank_name || ''}</dd> */}
                <dd className='col-sm-8 erp-invoice-print-label' style={{ fontSize: "12px" }}> {salaryCompanyBankComboRef.current?.company_branch_bank_name ?? ''}</dd>

                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}>Branch:</dt>
                {/* <dd className='col-sm-9 erp-invoice-print-label'> {companyBankDtl?.company_branch_bank_branch_name || ''}</dd> */}
                <dd className='col-sm-8 erp-invoice-print-label' style={{ fontSize: "12px" }}> {salaryCompanyBankComboRef.current?.company_branch_bank_branch_name ?? ''}</dd>

              </dl>
            </div>

            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}>IFSC:</dt>
                {/* <dd className='col-sm-9 erp-invoice-print-label'> {companyBankDtl?.company_branch_bank_ifsc_code || ''}</dd> */}
                <dd className='col-sm-9 erp-invoice-print-label' style={{ fontSize: "12px" }}> {salaryCompanyBankComboRef.current?.company_branch_bank_ifsc_code ?? ''}</dd>

                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}>A/C. No.:</dt>
                {/* <dd className='col-sm-9 erp-invoice-print-label'> {companyBankDtl?.company_branch_bank_account_no || ''}</dd> */}
                <dd className='col-sm-9 erp-invoice-print-label' style={{ fontSize: "12px" }}> {salaryCompanyBankComboRef.current?.company_branch_bank_account_no ?? ''}</dd>

              </dl>
            </div>

          </dl>
        </div>
      </>
    );
  };
  // ********* PrintOut Functionalities Ends. ****************************************


  // ********* ExportToExcel Functionalities Starts. ****************************************
  const addExcelFilter = (label, key, value, existingFilters) => {
    if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
  };

  const FnExportToExcel = async () => {
    debugger
    // Means No Data For Export. 
    if (employeeSalaryDetails.length === 0) { return null; }
    setIsLoading(true);
    // Preparing Data For ExportFile.
    let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

    // Excel Headers;
    jsonToExportExcel['headings'] = {
      'ReportName': `Salary Bank List ${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`,
      'CompanyName': COMPANY_NAME,
      'CompanyAddress': sessionStorage.getItem('companyAddress'),
    }

    // Applied Filters.
    let appliedFilters = {
      '0': `To, `,
      '1': salaryCompanyBankComboRef.current?.company_branch_bank_name ?? '',
      '2': salaryCompanyBankComboRef.current?.company_branch_bank_branch_name ?? '',
      // '3': `IFSC Code: ` + salaryCompanyBankComboRef.current?.company_branch_bank_ifsc_code || '',
      // '4': `A/c. No.: ` + salaryCompanyBankComboRef.current?.company_branch_bank_account_no || '',
      '3': `IFSC Code: ` + (salaryCompanyBankComboRef.current?.company_branch_bank_ifsc_code ?? ''),
      '4': `A/c. No.: ` + (salaryCompanyBankComboRef.current?.company_branch_bank_account_no ?? ''),
      // '5': `Year: ${salaryYearComboRef.current?.label || ""}`,
      // '6': `Month: ${salaryMonthComboRef.current?.label}(${salaryMonthComboRef.current?.value || ""})`,
    };
    appliedFilters[Object.keys(appliedFilters).length] = `Generated On: ${FnGetCurrentDateTime()}`
    jsonToExportExcel['filtrKeyValue'] = appliedFilters;

    // Details Table Columns and Accessors.
    jsonToExportExcel['columns'] = [
      { "Headers": 'Sr. No.', "accessor": 'sr_no' },
      // { "Headers": 'Employee Code', "accessor": 'employee_code' },
      // { "Headers": 'Employee Name', "accessor": 'employee_name' },

      { "Headers": 'Name As Per Bank', "accessor": 'account_name1' },
      { "Headers": 'Bank Name', "accessor": 'bank_name1' },
      { "Headers": 'Account No.', "accessor": 'account_no1' },
      { "Headers": 'IFSC Code', "accessor": 'ifsc_code1' },
      { "Headers": 'Salary Amount', "accessor": 'net_salary' },
    ]
    // Data For Table.
    employeeSalaryDetails.map((salaryRecord, Index) => {
      salaryRecord.sr_no = Index + 1;
      jsonToExportExcel['allData'][Index] = salaryRecord;

      // Check if it is the last item then push the last sum-of-totals object.
      if (Index === employeeSalaryDetails.length - 1) {
        jsonToExportExcel['allData'][employeeSalaryDetails.length] = {
          sr_no: 'Summary Totals',
          // employee_code: '',
          // employee_name: '',
          account_name1: '',
          account_no1: '',
          ifsc_code1: '',
          net_salary: parseFloat(employeeSalaryDetails.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2))
        }
      }
    });
    await exlsExp.current.excel(jsonToExportExcel, `${COMPANY_NAME.replace(/ /g, '_')}_Salary_Bank_List_${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`);

    setIsLoading(false);
  }
  // ********* ExportToExcel Functionalities Ends. ****************************************


  return (
    <>

      <ComboBox ref={comboDataFunc} />
      <ExcelExport ref={exlsExp} />



      <DashboardLayout>
        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div> :
          ''}

        {/* Card Starts*/}
        <div className="card mt-4">
          <div className="card-header py-0 main_heding">
            <label className="erp-form-label-lg">Salary Bank List Register</label>
          </div>

          {/* Card's Body Starts*/}
          <div className="card-body">

            <div className="row">
              {/* First Column Starts*/}
              <div className="col-sm-2 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year<span className="required">*</span> </Form.Label> </div>
                  <div className='col'>
                    <Select ref={salaryYearComboRef}
                      options={yearOpts}
                      inputId="cmb_salary_year"
                      value={yearOpts.find(option => option.value == cmb_salary_year) || null}
                      onChange={(selectedOpt) => {
                        setSalaryYear(selectedOpt.value);
                        salaryYearComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a year..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month<span className="required">*</span> </Form.Label> </div>
                  <div className='col'>
                    <Select ref={salaryMonthComboRef}
                      options={monthOpts}
                      inputId="cmb_salary_month"
                      value={monthOpts.find(option => option.value == cmb_salary_month) || null}
                      onChange={(selectedOpt) => {
                        setSalaryMonth(selectedOpt.value);
                        salaryMonthComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a month..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* First Column Ends*/}


              {/* Second Column Starts*/}
              <div className="col-sm-3 erp_form_col_div">

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Bank<span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={salaryBankComboRef}
                      options={salaryBankOpts}
                      inputId="cmb_salary_bank_id"
                      value={salaryBankOpts.find(option => option.value == cmb_salary_bank_id) || null}
                      onChange={(selectedOpt) => {
                        setSalaryBankId(selectedOpt.value);
                        salaryBankComboRef.current = selectedOpt;
                        FnComboOnChange('Bank');
                        FnValidateFields();
                      }}
                      placeholder="Search for a bank..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_bank_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Company Bank </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={salaryCompanyBankComboRef}
                      options={companyBankDtl}
                      inputId="cmb_company_bank_id"
                      value={companyBankDtl.find(option => option.value == cmb_company_bank_id) || null}
                      onChange={(selectedOpt) => {
                        setCompanybankId(selectedOpt.value);
                        salaryCompanyBankComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a Company bank..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }} optional="optional"
                    />
                    <MDTypography variant="button" id="error_cmb_salary_company_bank_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              <div className="col-sm-3 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                  <div className='col'>
                    <Select ref={departmentComboRef}
                      options={departmentOpts}
                      inputId="cmb_department_id"
                      value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                      onChange={(selectedOpt) => {
                        setDepartmentId(selectedOpt.value);
                        departmentComboRef.current = selectedOpt;
                        FnComboOnChange("Department");
                        FnValidateFields();
                      }}
                      placeholder="Search for a department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                  <div className='col'>
                    <Select ref={subDepartmentComboRef}
                      options={subDepartmentOpts}
                      inputId="cmb_sub_department_id"
                      value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                      onChange={(selectedOpt) => {
                        setSubDepartmentId(selectedOpt.value);
                        subDepartmentComboRef.current = selectedOpt;
                        FnComboOnChange("SubDepartment");
                        FnValidateFields();
                      }}
                      placeholder="Search for a sub-department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* Second Column Ends*/}

              {/* First Third Column Starts*/}
              <div className="col-sm-4 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Employee Type </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={employeeTypesComboRef}
                      options={employeeTypesOpts}
                      inputId="cmb_employee_type_id"
                      value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                      onChange={(selectedOpt) => {
                        setEmployeeTypeId(selectedOpt.value);
                        employeeTypesComboRef.current = selectedOpt;
                        FnComboOnChange('EmployeeType');
                        FnValidateFields();
                      }}
                      placeholder="Search for a employee type..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Employee </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={employeeComboRef}
                      options={employeeOpts}
                      inputId="cmb_employee_id"
                      value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                      onChange={(selectedOpt) => {
                        setEmployeeId(selectedOpt.value);
                        employeeComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a employee..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* First Third Column Ends*/}
            </div>

            <div className="row justify-content-center mt-2">
              <div className="col text-center">
                <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowSalaryData(); }} variant="button" fontWeight="regular">show Data</MDButton>
              </div>
            </div>

            {
              employeeSalaryDetails.length === 0
                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                : <> <hr />

                  <div className="row py-1">
                    <div className="text-end">
                      <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                        onClick={(e) => { FnExportToExcel(); }}  >Export Excel</MDButton>

                      <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                        onClick={(e) => { FnPrintReport(); }}  >Print</MDButton>
                    </div>
                  </div>

                  <div className="row px-lg-2 d-block">
                    <div className="card p-0">
                      <div className="card-header py-0 main_heding mb-0">
                        <label className="erp-form-label-md-lg">Employee's Salary Details</label>
                      </div>
                      {/* Card's Body Starts*/}
                      <div className="card-body p-0 print-content" ref={printComponentRef}>
                        {FnRenderSalaryDetailsTbl}
                      </div>
                    </div>
                  </div>

                </>
            }

            <hr />
            <div className="row text-center mt-3">
              <div className=''>
                {/* <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                  className={`erp-gb-button float-center`}
                  onClick={(e) => { goBack(); }}  > Back </MDButton> */}

                {
                  employeeSalaryDetails.length === 0
                    ? null
                    : <>
                      {
                        employeeSalaryDetails.length > 30
                          ? <>
                            <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                              className={`erp-gb-button float-center`} onClick={(e) => { goBack(); }}  > Back </MDButton>
                          </>
                          : null
                      }

                      <MDButton type="button" variant="button" fontWeight="regular"
                        className="erp-gb-button ms-2" onClick={(e) => { FnPrintReport(); }} >Print</MDButton>
                    </>
                }
              </div>
            </div>

          </div>
          {/* Card's Body Ends*/}

        </div>
        {/* Card Ends*/}

      </DashboardLayout>




    </>
  )
}

export default SalaryBankListRegister